@import "../../../styles/colors.module.scss";

#collection-request-group {
  .stack {
    .stack-title--title {
      font-size: 18px;
    }
  }
}

#collection-requests {
  .stack table.MuiTable-root {
    th:last-child {
      div {
        width: 160px;
        float: right;
      }
    }

    .request-traveler {
      font-size: 14px;
      font-style: normal;
      line-height: 18px;
      letter-spacing: 0em;
      .icon {
        margin-right: 8px;
      }
    }

    .booking-dates {
      color: $tg-text-gray;
    }

    .booking-status {
      display: flex;
      justify-content: flex-end;
      .booking-request-input {
        width: 160px;
      }
    }
  }
}
