@import "../../../styles/colors.module.scss";

.add-profiles-dialog {
  border-radius: 8px;

  .paper {
    position: absolute;
    top: 48px;
    margin: 0;
    overflow-y: auto;
  }

  .paper-scroll-paper {
    max-height: calc(100% - 96px);
  }

  .paper-width-sm {
    max-width: none;
  }

  .dialog-title {
    background: $tg-white;
    border-bottom: 1px solid $tg-border-gray;
    top: 0;
    position: sticky;
    z-index: map-get($z-index, "sticky");
  }

  .dialog-content {
    padding-top: 16px;
    padding-bottom: 8px;
    position: relative;
    width: 824px;
  }

  .dialog-actions {
    background: $tg-white;
    border-top: 1px solid $tg-border-gray;
    bottom: 0;
    padding: 8px 24px;
    margin: 0;
    position: sticky;
    z-index: map-get($z-index, "sticky");
  }

  .add-profiles-dialog-button-group {
    display: flex;

    span:nth-child(n + 2) {
      margin-left: 8px;
    }

    button.button.withBorder {
      border-width: 2px;
    }
  }

  .add-profiles-dialog__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
      font-size: 20px;
      font-weight: normal;
      margin: 0;
    }
  }

  .add-profiles-dialog__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .add-profiles-dialog__title {
      padding: 0;
    }
  }

  div.profile-input-rows {
    min-height: 300px;
  }

  div.profile-input-row {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    .autocomplete-input,
    .autocomplete-input--filled,
    .email-input,
    .tag-input,
    .text-input {
      width: 240px;
      margin-bottom: 0;

      .tg-input__value-container {
        padding-left: 8px;
      }
    }

    .select-toggle-menu {
      width: 240px;
    }

    .email-input,
    .text-input {
      height: 64px;
    }

    .autocomplete-input--filled {
      height: 48px;
    }

    > div:nth-child(n + 2) {
      margin-left: 16px;
    }

    &:last-child {
      .email-input .icon.error {
        visibility: hidden;
      }
    }

    .remove-button {
      margin-left: 16px;
    }
  }

  .input--text {
    margin-top: 8px;
    height: 64px;
  }
}
