@import "../../../styles/colors.module";

.read-only {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid $tg-border-gray;
  box-sizing: border-box;
  padding: 24px 8px;
  border-radius: 4px;
  height: 48px;

  .read-only-label {
    color: $tg-text-gray;
    font-size: 14px;
    text-align: left;
    white-space: nowrap;
  }

  .read-only-value {
    max-width: 70%;
    font-size: 14px;
    text-align: right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .tag-chip,
    .traveler-chip {
      margin-left: 4px;
    }
  }
}

.read-only--skeleton {
  transform: none !important;
  margin-bottom: 4px;
}
