@import "../../styles/colors.module.scss";

.page-with-sidebar-container {
  #grid-view-page {
    width: 100%; // main content well will fill space once sidebar stops growing
    padding: 16px 32px 80px 32px;
    max-height: 100vh;
    box-sizing: border-box;
    overflow-y: auto;
  }
}

#grid-view-page {
  position: relative;

  .page-header {
    .header-tools {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}

.grid-view-sidebar {
  min-height: 100vh;
  max-height: 100vh;
  width: 25%;
  min-width: 320px; // prevents buttons in small tile from being to close to the text label
  max-width: 520px; // prevents continuous growth of sidebar for stretching
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background: $tg-product-background-blue;
  overflow-y: auto;

  .inner {
    padding: 16px 32px;
  }

  .tile {
    margin-bottom: 8px;
  }
}

.travelers-empty-list-view {
  text-align: center;
  margin-top: 40px auto 0px auto;
  color: $tg-text-gray;
  font-size: 16px;

  border: 2px solid $tg-border-gray;
  border-radius: 8px;
  padding: 16px 32px;

  img {
    display: block;
    margin: 0 auto 16px auto;
    width: 120px;
  }

  .subtitle {
    text-align: center;
    display: flex;
    flex-direction: column;

    .subtitle-helper-text {
      margin: 12px 0;
      font-size: 12px;
    }
  }

  button.button {
    margin: 16px auto;
  }
}

.grid-collections {
  margin-bottom: 24px;

  .grid-collection-row {
    border: 2px solid $tg-border-gray;
    border-radius: 12px;
    padding: 24px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-bottom: 8px;
  }
}
