@import "../../styles/colors.module";

.sink-wrapper {
  .sink-title {
    margin-bottom: 20px;
  }

  .sink {
    display: block;
    position: relative;
    width: 100%;

    .pane {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      overflow-x: hidden;
      overflow-y: hidden;
      height: 100%;
      box-sizing: border-box;

      &.pinned {
        .grid-content.data {
          border-right: 1px solid $tg-border-gray;
        }
      }

      .scrollbar-container {
        .ps__rail-x {
          top: 32px;
          bottom: auto;
        }
        .ps__rail-x,
        .ps__rail-y {
          z-index: 1;
        }
      }
    }
  }
}
.sink-toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  .download-button {
    cursor: pointer;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .add-button {
      cursor: pointer;

      .app-icon-frame {
        margin-left: 8px;
        padding: 8px;
      }
    }
  }

  .keyword-search-input {
    &.open {
      width: 240px;
    }
  }

  & > div:last-child {
    justify-content: flex-end;
    & > div {
      margin-left: 8px;
      .app-icon-frame.square {
        padding: 8px;
      }
    }
  }
}

.user-add-input {
  display: flex;
  align-items: center;
  border-radius: 100px;
  border: 2px solid $tg-border-gray;
  box-sizing: border-box;
  font-size: 14px;
  height: 40px;
  width: 280px;
  overflow: hidden;

  .icon {
    margin-left: 8px;
  }

  > div {
    width: 100%;
  }

  .tg-input__control {
    border: none;
    box-shadow: none;
    cursor: text;
    min-height: auto;
  }
}
