@import "../../../styles/colors.module.scss";

.customize-report-dialog {
  border-radius: 0;

  .paper {
    position: absolute;
    margin: 0;
    overflow-y: auto;
  }

  .paper-scroll-paper {
    max-height: 100vh;
    min-height: 100%;
    min-width: 100%;
  }

  .paper-width-sm {
    max-width: auto;
  }

  .dialog-title {
    background: $tg-white;
    border-bottom: 1px solid $tg-border-gray;
    padding: 0 16px 0 48px;
    top: 0;
    position: sticky;
    z-index: map-get($z-index, "sticky");
  }

  .dialog-content {
    padding-left: 48px;
    padding-top: 8px;
    padding-bottom: 48px;
    position: relative;
    overflow-y: visible;
  }

  .customize-report-dialog__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;

    .customize-report-dialog__company-logo {
      box-sizing: border-box;
      height: 40px;
      width: 40px;
      padding: 4px;
    }
  }

  .customize-report-breadcrumb {
    display: flex;
    align-items: center;
    margin-top: 8px;

    p {
      margin: 0;
      font-size: 14px;
    }

    .icon {
      margin: 0 4px;
    }

    .customize-report-breadcrumb__link {
      color: $tg-product-blue;
      cursor: pointer;
    }
  }

  .customize-report-form {
    display: flex;
    flex-direction: column;
    align-items: center;

    .customize-report__report-icon {
      width: 46px;
    }

    h1 {
      display: block;
      text-align: center;
      font-size: 20px;
      color: $tg-black;
      font-weight: normal;
      margin: 16px 0px;
    }

    h2 {
      display: block;
      text-align: center;
      font-size: 16px;
      color: $tg-text-gray;
      font-weight: normal;
      margin: 0 0 24px;
    }

    h3 {
      display: block;
      text-align: center;
      font-size: 16px;
      color: $tg-black;
      font-weight: normal;
      margin-top: 0;
    }

    .customize-report__checkbox-section {
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 1em;
      width: 420px;

      .customize-report__checkbox-input {
        display: flex;
        align-items: center;
        width: 50%;
        height: 44px;
        font-size: 14px;
      }

      .checkbox-label {
        &.tag {
          display: inline-flex;
          justify-content: flex-start;
          align-items: center;
          border-radius: 4px;
          overflow: hidden;
          margin: 0px 4px 0px 0px;
          height: 24px;
          background-color: $tg-tag-light-blue;
          border: 2px solid $tg-tag-light-blue;
          box-sizing: border-box;
          font-size: 14px;

          .label {
            display: inline-block;
            color: $tg-product-dark-blue;
            padding: 0 6px 0 8px !important;
            line-height: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .customize-report__input-section {
      display: flex;
      flex-wrap: wrap;
      margin: auto;
      width: 380px;

      > div {
        margin-bottom: 8px;
      }

      .customize-report__checkbox-input {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 44px;
        font-size: 14px;
      }

      .date-input-flex {
        width: calc(50% - 4px);
        &:nth-of-type(even) {
          margin-left: 8px;
        }
      }

      .autocomplete-input--filled {
        width: 100%;
      }
    }

    .customize-report__download-button {
      width: 384px;
      margin-top: 8px;
    }
  }
}
