@import "../tile.scss";

#email-parsing-big-tile {
  padding: 24px 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  .email-parsing-big-tile__sub-title {
    margin: 0 0 16px;
    font-size: 16px;
    font-weight: normal;
  }

  .email-parsing-big-tile__description {
    margin: 0;
    font-size: 14px;
    color: $tg-text-gray;
    margin-bottom: 16px;
  }

  .email-parsing-big-tile__copy-button {
    background-color: $tg-light-orange;
    border-radius: 4px;
    box-sizing: border-box;
    color: $tg-orange;
    height: 40px;
    padding-left: 12px;
    padding-right: 12px;
    text-transform: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;

    span {
      white-space: nowrap;
    }

    .icon {
      margin-right: 16px;
      size: 16px;
      padding: 2px;
      box-sizing: border-box;
    }

    &:hover {
      background-color: darken($tg-light-orange, 5%);
    }
  }

  span.app-tooltip-wrapper:first-of-type {
    display: inline-flex;
    max-width: 100%;
  }

  .email-parsing-big-tile__email-group {
    margin-top: 24px;
    margin-bottom: 32px;
    overflow: hidden;
  }
}
