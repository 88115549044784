@import "../../styles/colors.module.scss";

.page-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  width: 100%;
  font-size: 14px;
}
