@import "../../../styles/colors.module.scss";

.grid-dialog {
  .paper-width-sm {
    min-width: 616px;
  }

  .dialog-title {
    padding: 0;
    border-bottom: 1px solid $tg-border-gray;
  }

  .dialog-content {
    overflow-y: auto;
    padding: 24px 24px 0;
  }

  .dialog-actions {
    border-top: 1px solid $tg-border-gray;
    margin: 0;
    padding: 16px 24px;
  }

  .grid-tips-carousel {
    width: 90%;
    margin: 24px auto 12px auto;

    .text {
      font-style: italic;
    }
  }

  .grid-dialog-form {
    .row {
      margin-bottom: 24px;

      &.color {
        input[type="text"] {
          height: 10px;
        }
      }
    }

    label {
      display: block;
      margin: 0px 0px 12px 0px;
    }

    .input-help-text {
      font-size: 12px;
      line-height: 16px;
      color: $tg-text-gray;

      &.input-help-text--error {
        display: flex;
        align-items: center;
        color: $tg-red;
        margin-top: 8px;
        min-height: 16px;

        .icon {
          margin-right: 4px;
        }
      }
    }

    .traveler-input {
      min-height: 52px;
    }

    .color-palette {
      width: 360px;
    }

    .filters-label {
      display: flex;
      justify-content: space-between;
    }

    .filter-container {
      border: 1px solid $tg-border-gray;
      border-radius: 4px;
      padding: 16px 0px;
      font-size: 14px;
      line-height: 18px;

      .filter-container-advanced {
        label {
          padding-left: 16px;
        }
      }

      .filter-container-standard,
      .travel-filter-type {
        padding-left: 16px;
        padding-right: 16px;
      }

      .description {
        color: $tg-dark-gray;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 12px;
      }

      .advanced-toggle {
        color: $tg-dark-gray;
        margin-top: 24px;
        font-size: 12px;
        line-height: 16px;
        span {
          cursor: pointer;
        }
      }
    }
  }

  .help-center-link {
    margin: 24px 0px;
    background-color: $tg-product-background-blue;
    border-radius: 4px;
    font-size: 12px;
    line-height: 16px;
    color: $tg-dark-gray;
    padding: 12px 24px;

    a {
      color: $tg-product-blue;
      text-decoration: none;
    }
  }
}
