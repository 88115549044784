@import "../../../../styles/colors.module";

.filter-menu {
  display: inline-block;
  margin-left: 8px;
}

.filter-popper {
  background: $tg-white;
  border: 2px solid $tg-border-gray;
  border-radius: 8px;
  box-shadow: $tg-shadow;
  opacity: 0;
  padding: 16px 8px 16px 24px;
  transition: opacity 0.3s ease-in-out;
  visibility: hidden;
  z-index: map-get($z-index, "menu");

  &.filter-popper--open {
    opacity: 1;
    visibility: visible;

    .filter-popper__content {
      opacity: 1;
      visibility: visible;
    }
  }

  .filter-popper__content {
    opacity: 0;
    visibility: hidden;

    h1 {
      font-size: 16px;
      font-weight: normal;
      color: $tg-text-gray;
      line-height: 1;
      margin: 0 0 16px;
    }

    .filter-popper__input-flex {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      > div:nth-child(2n) {
        margin-left: 8px;
      }
      .outlined-input {
        width: 200px;
        > div {
          > div {
            font-size: 14px;
            padding: 8px 16px 8px 16px;
            outline: none !important;
          }
        }

        fieldset {
          border: 1px solid $tg-border-gray;
        }

        svg {
          color: $tg-black;
          margin-right: 2px;
        }
      }

      .date-input {
        width: auto;
        height: 40px;
        width: 200px;
        border-color: $tg-border-gray;

        fieldset {
          border: 1px solid $tg-border-gray;
        }

        input {
          height: 100%;
          font-size: 14px;
          padding-left: 16px;
        }
      }

      .date-input--range {
        display: flex;
        flex-direction: column;

        > div:nth-child(2n) {
          margin-top: 8px;
        }
      }

      .autocomplete-input,
      .traveler-input {
        width: 200px;
        font-size: 14px;

        > div {
          border-color: $tg-border-gray !important;
        }
      }

      .select-toggle-menu {
        width: 200px;
      }

      .traveler-input {
        padding: 4px 8px;
      }

      .filter-popper__input--disabled {
        box-sizing: border-box;
        border-radius: 4px;
        border: 2px solid $tg-border-gray;
        background: $tg-background-gray;
        height: 40px;
        margin-left: 16px;
        width: 200px;
      }

      .remove-button {
        margin-left: 8px;
      }
    }

    .filter-popper__buttons {
      display: flex;
      justify-content: space-between;
      margin: 16px 28px 0 -12px;
    }
  }
}

.outlined-input {
  height: 40px;

  > div {
    height: 100%;
  }
}

.outlined-input__option--color {
  padding: 4px 8px;
  border-radius: 2px;
  font-size: 11px;
  text-transform: uppercase;
  line-height: 1;
}
