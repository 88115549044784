@import "../tile.scss";

.grid-documents-big-tile {
  padding: 24px 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  .mui-data-table-wrapper {
    margin-top: 8px !important;
  }
}
