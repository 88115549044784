@import "../../styles/colors.module.scss";

.traveler-chip {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  margin: 0px 4px 0px 0px;
  height: 24px;
  background-color: $tg-tag-light-blue;
  border: 2px solid $tg-tag-light-blue;
  box-sizing: border-box;
  font-size: 14px;

  &.removable:hover {
    border-color: $tg-product-dark-blue;
  }

  .label {
    display: inline-block;
    color: $tg-product-dark-blue;
    padding: 0 6px 0 8px !important;
    line-height: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .remove-button {
    width: 16px;
    height: 16px;
    overflow: hidden;
    cursor: pointer;
  }
}
