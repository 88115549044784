@import "../../../../styles/colors.module.scss";

div.multi-block-menu {
  position: absolute;
  background: $tg-white;
  border-radius: 4px;
  border: 2px solid $tg-border-gray;
  left: -2px;
  bottom: -56px;
  box-sizing: border-box;
  width: fit-content;
  margin: auto;
  display: flex;
  align-items: center;
  z-index: map-get($z-index, "doc-builder-tool");

  &.multi-block-menu--first {
    right: auto;
  }

  &.multi-block-menu--last {
    left: auto;
    right: -2px;
  }

  > div:nth-child(n + 2) {
    margin-left: 4px;
  }

  .multi-block-menu__option {
    .add-block-menu {
      padding: 0;
      border: none;
      position: inherit;
      top: inherit;
      left: inherit;
      visibility: visible;
      opacity: 1;
    }
  }
}
