@import "../../styles/colors.module.scss";

.icon {
  &.x-large {
    height: 48px;
    width: 48px;
    padding: 16px;
    border-radius: 100%;
  }

  &.large {
    height: 40px;
    width: 40px;
  }

  &.medium {
    height: 32px;
    width: 32px;
  }

  &.small {
    height: 24px;
    width: 24px;
  }

  &.x-small {
    height: 16px;
    width: 16px;
  }

  &.product-blue {
    color: $tg-product-blue;
  }

  &.yellow {
    color: $tg-yellow;
  }

  &.gray {
    color: $tg-text-gray;
  }

  &.dark-gray {
    color: $tg-dark-gray;
  }

  &.green {
    color: $tg-green;
  }

  &.orange {
    color: $tg-orange;
  }

  &.purple {
    color: $tg-purple;
  }

  &.red {
    color: $tg-red;
  }

  &.black {
    color: $tg-black;
  }

  &.white {
    color: $tg-white;
  }

  &.invert-black {
    path {
      background-color: $tg-white;
      color: $tg-black;
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  &.animated-spinning {
    animation: spin infinite 1s linear;
  }
}
