@import "../../styles/colors.module.scss";

.search-input {
  position: relative;
  display: flex;
  height: 40px;
  padding: 0 6px;
  border-radius: 400px;
  box-sizing: border-box;
  border: 2px solid $tg-border-gray;
  background-color: $tg-white;

  .search-input__field {
    border: none;
    background: transparent;
    box-sizing: border-box;
    padding: 0;
    width: 0px;
    font-size: 14px;
    outline: none;
    transition: 0.3s ease-in-out;

    &::placeholder {
      color: $tg-text-gray;
    }
  }

  .search-input__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    cursor: pointer;
    z-index: map-get($z-index, "icon");

    &:hover {
      color: $tg-product-blue;
    }
  }

  &.search-input--open {
    .search-input__field {
      width: 176px;
      padding-left: 4px;
    }
  }
}
