@import "../tile.scss";

.grid-details-tile {
  padding: 0 8px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    .user-profile-icons {
      flex-grow: 1;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      .user-profile-icon {
        margin-right: 4px;
      }
      .more-users-count {
        background-color: $tg-light-gray;
        font-size: 10px;
        display: flex;
        width: 28px;
        height: 28px;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        span {
          display: inline-block;
        }
      }
    }
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header,
    .description {
      margin: 0;
    }
  }

  .destination-chip {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    margin: 0px 8px 8px 0px;
    background: $tg-tag-light-blue;
    color: $tg-product-dark-blue;
    border-radius: 4px;
    box-sizing: border-box;
    height: 24px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    padding: 0 8px;
  }

  .details {
    margin-bottom: 0;
  }
}
