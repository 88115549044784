@import "../../../styles/colors.module";

.autocomplete-input--filled,
.traveler-input,
.tag-input-wrapper.has-label {
  box-sizing: border-box;
  min-height: 64px;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: $tg-background-gray;
  }
  &:focus-within {
    background-color: $tg-background-gray;
    label {
      color: $tg-product-blue;
    }
  }

  position: relative;
  border: 2px solid $tg-border-gray;
  padding: 4px 12px;
  border-radius: 4px;
  margin-bottom: 8px;

  .autocomplete-input__label,
  .tag-input__label {
    color: $tg-dark-gray;
    font-size: 12px;
  }

  .autocomplete-input__helper-label {
    color: $tg-dark-gray;
    font-size: 12px;
  }

  .tag-input {
    .tg-input__control {
      border: none;
      background: inherit;
      padding-right: 0px;
      box-shadow: none;
      min-height: auto;
    }
  }
}

.traveler-input {
  .traveler-chip {
    margin-bottom: 4px;
  }
}

.time-input {
  input {
    font-size: 14px;
  }
}

.date-input {
  ::-webkit-calendar-picker-indicator {
    margin-left: 0px;
  }
}

.date-input,
.email-input,
.text-input {
  width: auto;
  border-color: $tg-border-gray;

  fieldset {
    border: 1px solid $tg-border-gray;
  }

  input,
  textarea {
    cursor: text;
    font-size: 14px;
  }

  textarea {
    cursor: text;
    font-size: 14px;
  }

  &.text-input--textarea {
    height: auto !important;
  }
}

.email-input {
  > div {
    padding-right: 0;
    .icon {
      padding-right: 12px;
    }
  }
}

// @todo clean up this name so it doesn't collide so much with above .date-range-input
.date-input--range {
  display: flex;
  flex-direction: column;

  > div:nth-child(2n) {
    margin-top: 8px;
  }

  input {
    cursor: text;
  }
}

.autocomplete-input,
.autocomplete-input-grouped,
.autocomplete-input-checkbox,
.travel-filter-select {
  font-size: 14px;

  > div {
    border-color: $tg-border-gray !important;
  }

  .app-icon-frame {
    margin-right: 6px;
  }
}

.tag-input {
  font-size: 14px;

  > div {
    border-color: $tg-border-gray !important;
  }

  .tg-input__value-container {
    padding-left: 0px;
  }

  .tg-input__multi-value {
    margin-left: 0px;
    margin-right: 4px;
  }

  .tg-input__control--is-disabled {
    background-color: #f2f2f2;
  }

  .tag-input-option-wrapper {
    display: flex;
    align-items: center;

    span {
      display: inline-block;
    }

    &.unconfirmed {
      .icon.error {
        display: inline-block;
        margin: 1px 4px 0px 0px;
        vertical-align: top;
      }
    }
  }
}

.booking-request-input {
  .autocomplete-input--filled {
    min-height: 40px;
    margin-top: 0;
    margin-bottom: 0;
    padding-right: 8px;
    display: flex;
    align-items: center;

    .autocomplete-input {
      width: 100%;
    }

    .autocomplete-input__control {
      transition: none;
      margin: 0;

      > div {
        margin-top: 0;
      }
    }
  }

  &.initiated {
    .autocomplete-input--filled {
      border-color: $tg-light-blue;
      background-color: $tg-light-blue !important;
      .autocomplete-input__single-value {
        color: $tg-blue;
      }
    }
  }

  &.requested {
    .autocomplete-input--filled {
      border-color: $tg-light-red;
      background-color: $tg-light-red !important;
      .autocomplete-input__single-value {
        color: $tg-red;
      }
    }
  }

  &.ready {
    .autocomplete-input--filled {
      border-color: $tg-light-orange;
      background-color: $tg-light-orange !important;
      .autocomplete-input__single-value {
        color: $tg-orange;
      }
    }
  }

  &.booked {
    .autocomplete-input--filled {
      border-color: $tg-light-green;
      background-color: $tg-light-green !important;
      .autocomplete-input__single-value {
        color: $tg-green;
      }
    }
  }
}

.tg-input__multi-value__remove {
  svg {
    margin-right: 0 !important;
  }
}

.booking-request-segment-input {
  .segment-row {
    margin-bottom: 16px;

    .start {
      margin-bottom: 16px;
    }

    .input-controller-helper {
      display: none;
    }

    .time-container {
      margin-top: 4px;
      border: 2px solid $tg-border-gray;
      max-height: 40px;
      border-radius: 4px;
      padding: 0 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;

      .label {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .radio-toggle-button {
          margin-right: 8px;
        }
      }

      .time-input {
        .MuiInputBase-input {
          padding-right: 0px;
        }
        .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
          border: none;
          padding-right: 0px;
        }
      }
    }
  }
}

.radio-toggle-button {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 2px solid $tg-product-blue;
  border-radius: 50%;
  cursor: pointer;

  &.disabled {
    cursor: default;
    border-color: $tg-border-gray;
  }

  .inner-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $tg-product-blue;
    margin: 3px 0px 0px 3px;
  }
}
