@import "../../styles/colors.module.scss";

.select-toggle-menu {
  height: 40px;

  .select-toggle-menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    padding: 4px 8px;
    border: 2px solid $tg-border-gray;
    border-radius: 4px;
    background-color: $tg-white;
    cursor: pointer;
    overflow: hidden;
    box-sizing: border-box;
    height: 100%;

    .selected-wrapper {
      flex-grow: 1;
      overflow: hidden;
      color: $tg-black;
    }

    .menu-arrow {
      vertical-align: middle;
    }
  }

  &.disabled {
    .select-toggle-menu-header {
      background-color: $tg-background-gray;
      cursor: default;

      .menu-arrow,
      .placeholder {
        visibility: hidden;
      }
    }
  }

  .airport-chip,
  .tag-chip,
  .traveler-chip {
    margin-right: 4px;
    max-width: 80%;
  }
}

.select-toggle-menu-popper {
  position: absolute;
  z-index: map-get($z-index, "menu");
  border: 2px solid $tg-border-gray;
  border-radius: 4px;
  box-shadow: $tg-shadow;
  box-sizing: border-box;
  color: $tg-black;
  margin-top: 4px;
  padding-top: 16px;
  background-color: $tg-white;

  .search-header {
    padding: 0px 24px 16px;
    border-bottom: 1px solid $tg-border-gray;

    input {
      display: block;
      box-sizing: border-box;
      width: 100%;
      border: 2px solid $tg-border-gray;
      border-radius: 4px;
      outline: none !important;
      padding-left: 16px;
      padding-right: 16px;
      height: 40px;
      font-size: 14px;
    }
  }

  .options-container {
    padding: 8px;
    max-height: 300px;
    overflow-y: auto;

    .option-row {
      padding: 8px 16px;
      display: flex;
      border-radius: 4px;
      justify-content: flex-start;
      align-items: center;

      &:hover {
        background-color: $tg-background-gray;
        cursor: pointer;
      }

      .toggle-switch {
        margin-right: 8px;
        min-width: 24px;
      }

      .option-container {
        flex-grow: 1;
        overflow: hidden;

        .tag-chip {
          cursor: pointer;
        }
      }

      .traveler-chip {
        pointer-events: none;
      }
    }
  }
}

.MuiSkeleton-root.select-toggle-menu {
  transform: none;
  transform-origin: none;
}
