@import "../../styles/colors.module.scss";

.toggle-text {
  background-color: $tg-light-gray;
  border-radius: 100px;
  height: 20px;
  padding: 2px;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  &.disabled {
    .option {
      cursor: default;
      &.selected {
        color: $tg-gray;
      }
    }
  }

  .option {
    height: 16px;
    line-height: 16px;
    display: inline-flex;
    align-items: center;
    padding: 2px 16px;
    border-radius: 100px;
    cursor: pointer;

    &.left {
      margin-right: 4px;
    }

    &.selected {
      background-color: $tg-white;
      color: $tg-product-blue;
    }
  }
}
