@import "../../styles/colors.module.scss";

#admin-page {
  .mui-data-table-wrapper {
    margin-bottom: 48px;
  }

  .company-features {
    .feature-row {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 24px;

      .feature-name {
        font-size: 16px;
        width: 160px;
      }

      .add-button {
        cursor: pointer;
      }

      input {
        padding: 8px;
        font-size: 16px;
      }
      .text-input {
        width: 90% !important;
      }
    }
  }
}
