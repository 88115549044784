@import "../tile.scss";

.grid-documents-tile {
  padding: 0 8px;

  .header {
    margin-bottom: 24px;
  }

  div.header:first-of-type {
    margin-bottom: 0;
  }

  div.document-link {
    border: 2px solid $tg-border-gray;
    border-radius: 4px;
    box-sizing: border-box;
    cursor: pointer;
    color: $tg-black;
    padding: 8px;
    transition: background-color 0.3s ease-in-out;

    &:hover {
      background-color: $tg-background-gray;
    }

    &:nth-child(n + 2) {
      margin-top: 8px;
    }

    &:first-child {
      margin-top: 16px;
    }

    &:last-child {
      margin-bottom: 8px;
    }
  }
}
