@import "../../styles/colors.module.scss";

#people-page {
  .people-page-utils {
    display: flex;
    justify-content: flex-end;

    .people-page-button-group {
      display: flex;

      .people-page-search-input {
        min-width: 40px;
      }

      .button {
        margin-left: 8px;
      }
    }
  }

  .mui-data-table-wrapper {
    margin-top: 24px;
  }
}
