@import "../../../../styles/colors.module";

.keyword-search-input {
  position: relative;
  display: flex;
  height: 40px;
  padding: 0 6px;
  border-radius: 400px;
  box-sizing: border-box;
  border: 2px solid $tg-border-gray;
  background-color: $tg-white;
  width: 40px;
  transition: 0.3s ease-in-out;

  input {
    display: block;
    border: none;
    background: transparent;
    box-sizing: border-box;
    padding: 0;
    width: 0px;
    font-size: 14px;
    outline: none;
    transition: 0.3s width ease-in-out;

    &::placeholder {
      color: $tg-text-gray;
    }
  }

  div.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    cursor: pointer;
    z-index: map-get($z-index, "icon");

    &:hover {
      color: $tg-product-blue;
    }
  }

  &.open {
    width: 352px;
    padding-left: 10px;
    input {
      width: 100%;
    }

    &.right {
      padding-left: 5px;
      div.icon {
        margin-right: 8px;
      }
    }
  }
}
