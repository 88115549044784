@import "../../styles/colors.module.scss";

.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  height: 40px;

  h1 {
    color: $tg-black;
    font-size: 20px;
    font-weight: normal;
    margin: 0;
  }
}
