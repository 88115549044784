@import "../../../styles/colors.module.scss";

.cell-input {
  position: relative;

  .col-input-expander {
    font-size: 14px;
    letter-spacing: 0;
    position: absolute;
    visibility: hidden;
    left: 14px;
    white-space: pre;
  }

  .text-input {
    margin: 0;

    fieldset {
      border-color: transparent;
      border-width: 2px !important;
    }

    &:focus-within {
      fieldset {
        border-color: $tg-product-blue;
      }
    }

    &.text-input--textarea {
      > div {
        padding-top: 4px;
        padding-bottom: 4px;
        margin-top: 4px;
        margin-bottom: 4px;
      }
    }
  }
}
