@import "../../../../styles/colors.module";

.user-menu {
  display: inline-block;
  margin-left: 8px;
}

.user-popper {
  background: $tg-white;
  border: 2px solid $tg-border-gray;
  border-radius: 8px;
  box-shadow: $tg-shadow;
  opacity: 0;
  padding: 16px 24px;
  transition: opacity 0.3s ease-in-out;
  visibility: hidden;
  z-index: map-get($z-index, "menu");

  &.user-popper--open {
    opacity: 1;
    visibility: visible;

    .user-popper__content {
      opacity: 1;
      visibility: visible;
    }
  }

  .user-popper__content {
    opacity: 0;
    visibility: hidden;

    h1 {
      font-size: 16px;
      font-weight: normal;
      color: $tg-text-gray;
      line-height: 1;
      margin: 0 0 16px;
    }

    .traveler-input {
      width: 360px;
    }
  }
}
