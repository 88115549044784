@import "../../../styles/colors.module";

$row-height: 56px;

.grid {
  height: 100%;

  .grid-content {
    position: relative;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;

    &.head {
      margin-bottom: 15px;

      .grid-row {
        font-size: 14px;
        background-color: #fafafa;
        color: #9e9e9e;
        user-select: none;
        min-height: auto;
        border: none !important;

        .grid-cell {
          .inner {
            display: flex;
            align-items: center;
            position: relative;

            .label {
              padding-left: 8px;
            }
          }
          .column-handle {
            position: absolute;
            width: 12px;
            z-index: 2;
            cursor: ew-resize;

            top: 0;
            right: 12px;

            &.disabled {
              cursor: default;
              .handle-bar {
                display: none;
              }
            }

            .handle-bar {
              position: absolute;
              top: 4px;
              left: 6px;
              width: 3px;
              height: 70%;
              background-color: #0000ff;
              z-index: 1;

              opacity: 0;
              transition: opacity 100ms ease-in;
            }

            &:not(.disabled) {
              &.active,
              &:hover {
                .handle-bar {
                  opacity: 0.7;
                }
              }
            }
          }
        }
      }
    }

    .grid-row {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      overflow: hidden;
      height: $row-height;
      min-height: $row-height;
      width: 100%;
      border-top: 1px solid $tg-border-gray;
      box-sizing: border-box;

      &:last-child {
        border-bottom: 1px solid $tg-border-gray;
      }

      &:nth-child(even) {
        background-color: #fafafa;
      }

      &.pinned {
        .group-title {
          .inner {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            padding: 0px 0px 8px 8px;
            box-sizing: border-box;
          }
        }
      }

      .grid-cell {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        height: 100%;

        .inner {
          height: 100%;
          width: 100%;
          overflow: hidden;
        }
      }
    }
  }
}

.sink-wrapper.hasGroup {
  .grid {
    .grid-row {
      &.pinned {
        background-color: $tg-background-gray !important;
      }
      &:nth-child(even) {
        background: none;
      }
    }
  }
}
