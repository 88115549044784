@import "../../../styles/colors.module.scss";

.schedule-event-dialog__overlay {
  .paper {
    top: 24px;
    right: 24px;
  }

  .dialog-content {
    border-top: 1px solid $tg-border-gray;
    border-bottom: 1px solid $tg-border-gray;
    width: 344px; // matches quick add width
    padding: 16px 24px;
  }

  .dialog-actions {
    margin: 8px 16px;
  }

  .schedule-event-dialog__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
      font-size: 20px;
      font-weight: normal;
      margin: 0;
    }
  }

  .schedule-event-dialog-button-group {
    display: flex;

    button.button:nth-child(n + 2) {
      margin-left: 8px;
    }
  }

  .schedule-event-dialog__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .schedule-event-dialog__title {
      padding: 0;
    }
  }

  .schedule-event-dialog__content-flex {
    display: flex;
    justify-content: space-between;
    > div {
      width: calc(50% - 4px);
    }
  }

  .schedule-event-dialog__content-helper-label {
    font-size: 12px;
    color: $tg-text-gray;
    margin: -4px 0 8px;
  }

  .text-input {
    margin-top: 8px;
    height: 64px !important;
  }

  .date-input,
  .time-input {
    height: 64px !important;
  }
}
