@import "../../../styles/colors.module.scss";

.room-block-dialog {
  border-radius: 8px;

  .paper {
    padding: 24px 16px;
    position: absolute;
    margin: 0;
  }

  .paper-width-sm {
    max-width: inherit;
  }

  .room-block-dialog__title {
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
      font-size: 20px;
      font-weight: normal;
      margin: 0;
    }
  }

  .room-block-dialog__body {
    color: $tg-text-gray;
    text-align: center;
  }

  .room-block-dialog__upload-options {
    display: flex;
    margin: 24px 48px;

    .upload-option {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 200px;
      padding: 20px;
      border: 2px solid transparent;
      transition: 0.3s ease-in-out;
      border-radius: 12px;

      &:hover {
        border-color: $tg-product-blue;
      }

      &:nth-child(n + 2) {
        margin-left: 24px;
      }

      .upload-option__image-wrapper {
        height: 120px;
        display: flex;
        align-items: center;
      }

      .upload-option__time-label {
        color: #1bb157;
        text-transform: uppercase;
        font-size: 12px;
        margin: 0 0 8px;
      }

      .upload-option__name {
        font-size: 18px;
        margin: 0 0 8px;
      }

      .upload-option__description {
        color: $tg-text-gray;
        font-size: 14px;
        text-align: center;
        margin: 0 0 16px;
      }

      .upload-option__sub-description {
        color: $tg-text-gray;
        font-size: 12px;
        text-align: center;
        margin: 0 0 16px;
      }

      .upload-option__download-link {
        color: $tg-product-blue;
        margin: -8px 0 12px;
        font-size: 14px;
        padding: 8px 0;
        cursor: pointer;
        position: relative;
        display: inline-block;

        &:after {
          content: "";
          background: $tg-product-blue;
          position: absolute;
          width: 0;
          height: 2px;
          display: block;
          right: 0;
          bottom: 2px;
          transition: width 0.3s ease;
        }

        &:hover {
          &:after {
            width: 100%;
            left: 0;
          }
        }
      }
    }
  }
}
