@import "../../styles/colors.module.scss";

#settings-page,
.settings-subpage {
  margin: 0 0px 48px;
}

.settings-page__header-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .settings-page__sub-header {
    margin-bottom: 0;
  }
}

.settings-page__header {
  font-size: 20px;
  height: 40px;
  margin-top: 0;
  display: flex;
  align-items: center;
  font-weight: normal;
  margin-bottom: 32px;
}

.settings-page__sub-header {
  display: flex;
  align-items: center;
  color: $tg-black;
  font-size: 14px;
  font-weight: normal;
  margin: 0 0 16px;

  .icon {
    margin-left: 8px;
  }
}

.settings-page__hint-text {
  font-size: 14px;
  color: $tg-text-gray;
  margin: 0 0 16px;
}

.settings-subpage {
  .settings-page__button-group {
    display: flex;

    button.button:nth-child(n + 2) {
      margin-left: 8px;
    }
  }

  div.settings-page__section-wrapper {
    max-width: 600px;

    &.--with-border {
      border: 2px solid $tg-border-gray;
      border-radius: 12px;
      padding: 16px;
    }

    &:nth-of-type(n + 2) {
      margin-top: 32px;
    }

    .settings-page__header-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }

    .settings-page__sub-header {
      font-size: 14px;
    }

    a {
      text-decoration: none;
      &:nth-of-type(n + 2) {
        button {
          margin-top: 8px;
        }
      }
    }
  }

  button.settings-page__button-link {
    text-transform: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: $tg-background-gray;
    border: 2px solid $tg-background-gray;
    box-sizing: border-box;
    padding: 16px;
    width: 292px;
    box-sizing: border-box;
    transition: 0.3s ease-in-out;

    .icon {
      margin-right: 16px;
    }

    &:hover {
      border-color: $tg-border-gray;
    }

    &.settings-page__button-link--blue {
      color: $tg-white;
      background: $tg-product-blue;
      border: none;

      &:hover {
        background-color: lighten($tg-product-blue, 5%);
      }
    }

    &:nth-of-type(n + 2) {
      margin-top: 8px;
    }

    &.settings-page__button-link--square {
      width: 92px;
      height: 92px;
      max-width: 92px;
      max-height: 92px;
      padding: 8px;
      display: inline-flex;
      align-items: center;

      span:first-child {
        max-height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      // this makes the doc template tiles have proper margin within the 600px container
      &:nth-of-type(n + 2) {
        margin-left: 8px;
        margin-top: 0;
      }

      &:nth-of-type(n + 7) {
        margin-top: 8px;
      }

      &:nth-of-type(6n + 1) {
        margin-left: 0;
      }
    }
  }

  .settings-flex {
    display: flex;
    align-items: flex-start;
  }

  .settings-flex-column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .date-input {
    max-width: 200px;
  }

  .text-input {
    max-width: 240px;
    margin-bottom: 24px;
  }

  .settings-input-flex {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;

    span {
      padding-bottom: 8px;
      margin-bottom: 24px;
      margin-left: 8px;
      font-size: 14px;
    }
  }

  .workspace-icon-wrapper {
    position: relative;
    align-self: flex-start;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    box-sizing: border-box;
    border: 2px solid $tg-border-gray;
    border-radius: 4px;
    margin-bottom: 24px;

    .workspace-icon-helper {
      position: absolute;
      top: -8px;
      right: -8px;
    }
    .company-logo {
      width: 40px;
    }
  }

  .settings-page__logo-suggestions {
    margin-left: 24px;

    .settings-page__sub-header {
      margin-bottom: 8px;
      font-size: 14px;
    }
  }
}

.settings-subpage-breadcrumb {
  display: flex;
  align-items: center;
  height: 40px;
  margin: 3px 0px 6px 0px;

  p {
    margin: 0;
    font-size: 14px;
  }

  .icon {
    margin: 0 4px;
  }

  .settings-subpage-breadcrumb__link {
    color: $tg-product-blue;
    cursor: pointer;
  }
}

div.settings-page__property-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $tg-white;
  border: 2px solid $tg-border-gray;
  border-radius: 8px;
  padding: 12px 16px;
  margin-top: 8px;
  cursor: move;

  &.readonly {
    cursor: default;

    &:hover {
      border-color: $tg-border-gray;
    }
  }

  &:hover {
    border-color: $tg-product-blue;
  }

  &:first-of-type {
    margin-top: 24px;
  }

  .settings-page__property-name {
    font-size: 14px;
    width: 160px;
  }

  .autocomplete-input {
    width: 200px;
  }

  .settings-page__property__button-group {
    display: flex;

    button.button:nth-child(n + 2) {
      margin-left: 8px;
    }
  }
}
