@import "../../../styles/colors.module.scss";

.user-dialog {
  border-radius: 8px;

  &.user-dialog-standard {
    .MuiPaper-root {
      width: 592px;
      position: absolute;
      right: 24px;
      top: 24px;
      margin: 0;
      height: calc(100% - 48px);
    }

    .dialog-title {
      padding: 16px 24px;
    }

    .dialog-title--flex {
      display: flex;
      align-items: center;

      .app-tooltip-wrapper {
        margin-right: 16px;
        pointer-events: none;

        .user-profile-icon {
          width: 40px;
          height: 40px;
          font-size: 16px;
        }
      }

      .user-profile-icon--skeleton {
        width: 40px !important;
        height: 40px !important;
        margin-right: 16px;
      }
    }

    .MuiDialogContent-root {
      border-top: 1px solid $tg-border-gray;
      border-bottom: 1px solid $tg-border-gray;
      display: flex;
      padding: 0;
      position: relative;
      overflow-y: auto;
      width: 100%;
      box-sizing: border-box;
    }

    .MuiDialogActions-root {
      margin: 8px 16px;
    }
  }

  .MuiDialogTitle-root {
    h2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 20px;
      font-weight: normal;
      margin: 0px;
    }

    .user-dialog__lock-indicator {
      color: $tg-red;
      display: flex;
      align-items: center;
      font-size: 11px;
      margin-left: 8px;
      padding-bottom: 4px;

      .icon {
        margin-right: 4px;
        padding-bottom: 2px;
      }
    }

    .title {
      flex-grow: 1;
    }

    .tools {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .profile-icon {
        width: 50%;
      }

      .buttons {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .user-dialog__content {
    width: 100%;
    padding: 16px 24px;

    .input-controller {
      margin-bottom: 8px;
    }

    .read-only,
    .read-only--skeleton {
      margin-bottom: 8px;
    }

    .time-input {
      width: 128px;
    }

    @media (min-width: 1280px) {
      overflow-y: auto;
    }

    .autocomplete-input,
    .autocomplete-input-grouped {
      height: inherit;
      min-height: none;
    }

    .traveler-input {
      min-height: 40px;
    }

    .accordion-group {
      .accordion-section {
        .header {
          padding: 8px;
          .title {
            font-size: 14px;
          }
        }
      }
    }

    .tg-form {
      padding-bottom: 24px;
    }

    .profile-form {
      margin-bottom: 24px;

      .unfolded {
        .tg-form {
          padding-bottom: 0;
        }
      }
    }

    .profile-document-form,
    .profile-program-form {
      .readonly-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;

        .readonly-header--title {
          font-size: 18px;
        }

        .readonly-header--edit {
          text-align: right;
          font-size: 14px;
          cursor: pointer;
          color: $tg-product-blue;
        }
      }
    }

    .section-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 16px;

      .section-header-title {
        font-size: 18px;
      }

      .section-header-link {
        font-size: 14px;
        text-align: right;
        cursor: pointer;
        color: $tg-product-blue;

        &.section-header-link--disabled {
          color: $tg-text-gray;
          pointer-events: none;
        }
      }
    }

    .tab-header {
      margin-bottom: 24px;
    }

    .user-dialog-section {
      margin-bottom: 24px;

      .user-dialog-section--row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 2px solid $tg-border-gray;
        box-sizing: border-box;
        padding: 24px 8px;
        border-radius: 4px;
        height: 48px;
        margin-bottom: 8px;

        .title {
          color: $tg-black;
          font-size: 14px;
          text-align: left;
          white-space: nowrap;
        }

        button {
          padding: 12px;
        }
      }
    }

    .user-dialog-section--empty-state {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      border-radius: 4px;
      border: 2px solid $tg-border-gray;
      color: $tg-text-gray;
      height: 80px;
    }

    .tab-header {
      margin-bottom: 24px;
    }

    .user-dialog-section {
      margin-bottom: 24px;

      .user-dialog-section--row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 2px solid $tg-border-gray;
        box-sizing: border-box;
        padding: 24px 8px;
        border-radius: 4px;
        height: 48px;
        margin-bottom: 8px;

        .title {
          color: $tg-black;
          text-align: left;
          white-space: nowrap;
        }
      }
    }
  }

  .user-dialog__form-header {
    font-size: 20px;
    font-weight: normal;
    margin: 0;
  }

  .user-dialog__form-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .user-dialog__gds-info {
      display: flex;
      align-items: center;

      span {
        color: $tg-green;
        font-size: 14px;
        display: inline-block;
        line-height: 100%;
        padding-top: 2px;
      }
    }
  }

  .autocomplete-input--filled {
    z-index: inherit;
  }

  .user-dialog__content-flex {
    display: flex;
    justify-content: space-between;
    > div {
      width: calc(50% - 4px);
    }
  }

  .user-dialog__content-helper-label {
    font-size: 12px;
    color: $tg-text-gray;
    margin: -4px 0 8px;
  }

  .input--text {
    margin-top: 8px;
  }

  .MuiDialogActions-root {
    display: flex;
    justify-content: flex-end;
  }
}
