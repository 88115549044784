@import "../../../../styles/colors.module";

.sink-filter-menu {
  width: 756px;

  .title {
    font-size: 16px;
    color: $tg-text-gray;
    margin-bottom: 8px;
  }
  .add-filter-row {
    span {
      color: $tg-product-blue;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .autocomplete-input--filled {
    min-height: auto;
    display: flex;
    align-items: center;
    height: 40px;
    width: 248px;
    padding: 4px 8px;
    margin: 0;

    .autocomplete-input {
      width: 100%;
    }

    .autocomplete-input__control {
      margin-top: 0;
    }
  }

  .input-placeholder,
  .select-toggle-menu {
    width: 248px;
  }

  .filter-row {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 8px;

    .article-select {
      margin: 0 8px;
      width: 72px;
    }

    .input {
      .input-placeholder {
        background-color: $tg-background-gray;
        border-radius: 4px;
        min-width: 100%;
        height: 40px;
        min-width: 200px;
      }
      .input-controller-helper {
        display: none;
      }

      .date-range-input .date-input-flex {
        width: 150px;
      }
    }

    .remove-filter {
      padding-top: 4px;
    }

    .andor {
      font-size: 14px;
      height: 40px;
      box-sizing: border-box;
      border-radius: 4px;
      margin: 0 8px;
      display: flex;
      align-items: center;
      color: $tg-product-blue;

      background-color: $tg-background-gray;
      color: $tg-product-blue;
      padding: 0px 16px;
      div {
        text-align: center;
      }
    }
  }

  .add-filter-row {
    margin-top: 8px;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 16px -24px 0;
    border-top: 1px solid $tg-border-gray;
    padding: 16px 16px 0;
  }
}
