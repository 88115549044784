@import "../../styles/colors.module.scss";

.color-palette-popper__container {
  position: relative;
}

.color-palette-popper {
  background: $tg-white;
  border-radius: 8px;
  box-shadow: $tg-shadow;
  margin: 8px 0;
  max-height: calc(100vh - 96px);
  overflow: hidden;

  .color-palette-popper__content {
    width: 216px;
    padding: 16px 8px 16px 16px;
    font-size: 14px;

    .color-palette-popper__colors {
      width: 100%;
      margin-top: 16px;
    }
  }
}

.color-palette-popper__anchor {
  .palette {
    margin: 0px;
  }
  .noselect {
    border-radius: 4px;
    border: 2px solid $tg-border-gray;
    cursor: pointer;
    width: 24px;
    height: 24px;
    text-align: center;

    svg {
      color: $tg-black;
      vertical-align: top;
      display: inline-block;
    }
  }
}
