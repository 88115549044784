@import "../../../../styles/colors.module.scss";

.schedule-block-wrapper {
  width: 100%;
  padding: 8px 0;

  .schedule-block-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .schedule-block-actions__title {
      color: $tg-text-gray;
      font-size: 20px;
    }

    .schedule-block-actions__button-group {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      position: relative;

      .autocomplete-input {
        width: 200px;
        display: block;
      }

      > button.button {
        margin-left: 8px;
      }

      button.withBorder {
        border-width: 2px;
      }
    }
  }

  .schedule-block-content {
    div.schedule-block-date-group:first-child {
      margin-top: 24px;
    }

    div.schedule-block-date-group:last-child {
      padding-bottom: 24px;
      div.schedule-block-content__card-wrapper:last-child {
        margin-bottom: 0;
      }
    }

    .schedule-block-date-group__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 24px;

      .schedule-block-content__bullet {
        height: 12px;
        width: 12px;
        border-radius: 4px;
        margin: 0 24px;
        background: $tg-black;
      }

      .schedule-block-content__date {
        font-size: 20px;
        margin: 0;
        font-weight: normal;
      }

      .schedule-block-date-group__button {
        padding: 4px;
        svg {
          color: $tg-red;
        }
      }
    }

    .schedule-block-content__time {
      font-size: 14px;
      font-weight: normal;
      font-family: "Circular Bold" !important;
      margin: 0 0 8px;
    }

    div.schedule-block-content__card-wrapper {
      padding-bottom: 16px;
      position: relative;

      .schedule-block-content__connector-line {
        position: absolute;
        width: 2px;
        left: 32px;
        height: 100%;
        background-color: $tg-border-gray;
      }

      &:last-child {
        padding-bottom: 0;
        margin-bottom: 24px;
      }
    }

    div.schedule-block-content__card {
      display: flex;
      align-items: flex-start;
      background-color: $tg-white;
      border: 2px solid $tg-border-gray;
      border-radius: 8px;
      cursor: pointer;
      padding: 16px 16px;
      position: relative;
      transition: 0.3s ease-in-out;

      @media (max-width: 768px) {
        flex-direction: column;
        .schedule-block-content__card-notes {
          margin-left: 0 !important;
          margin-top: 16px;

          .icon {
            padding: 0 4px;
            margin-right: 16px !important;
          }
        }
      }

      &:hover {
        border-color: $tg-product-blue;
        .schedule-block-content__card-menu {
          visibility: visible;
          opacity: 1;
        }
      }

      &:focus-within {
        border-color: $tg-border-gray;
      }

      .schedule-block-content__card-menu {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        background: $tg-white;
        border-radius: 400px;
        padding: 4px;
        top: 4px;
        right: 16px;
        display: flex;
        align-items: center;
        transition: all 0.3s ease-in-out;

        > div:nth-child(n + 2) {
          margin-left: 4px;
        }
      }

      .schedule-block-content__card-details {
        display: flex;
        align-items: flex-start;
        width: 100%;

        .icon-wrapper {
          min-width: 32px;
          min-height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          margin-right: 16px;

          &.icon-wrapper--blue {
            color: $tg-product-blue;
            background: $tg-product-background-blue;
          }

          &.icon-wrapper--green {
            color: $tg-green;
            background: $tg-light-green;
          }

          &.icon-wrapper--orange {
            color: $tg-orange;
            background: $tg-light-orange;
          }

          &.icon-wrapper--purple {
            color: $tg-purple;
            background: $tg-light-purple;
          }

          &.icon-wrapper--red {
            color: $tg-red;
            background: $tg-light-red;
          }
        }
      }

      .schedule-block-content__card-notes {
        margin-left: 48px;
        min-width: 240px;
        width: 100%;

        .schedule-block-content__card-notes-header {
          display: flex;
          align-items: center;
          margin-bottom: 8px;

          .icon {
            border: none;
            font-size: 20px;
            margin-right: 8px;
          }

          .schedule-block-content__card-header {
            margin-bottom: 0;
            font-size: 14px;
            font-family: "Circular Bold" !important;
          }
        }

        .text-input {
          > div {
            padding: 8px;
          }
          fieldset {
            border: 2px solid $tg-border-gray;
          }
          textarea {
            font-size: 14px !important;
          }

          :focus-within {
            fieldset {
              border-color: $tg-product-blue;
            }
          }
        }
      }

      .schedule-block-content__card-header {
        font-size: 16px;
        font-weight: normal;
        margin: 0;
      }

      p.schedule-block-content__card-text {
        color: $tg-text-gray;
        font-size: 14px;
        margin: 8px 0 8px 0;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
