@import "../../../../../styles/colors.module.scss";

.template-editor-menu {
  display: inline-block;
}

.template-editor-popper {
  background: $tg-white;
  border: 2px solid $tg-border-gray;
  border-radius: 8px;
  box-shadow: $tg-shadow;
  opacity: 0;
  padding: 16px 24px;
  margin: 8px 0;
  transition: opacity 0.3s ease-in-out;
  visibility: hidden;
  max-height: calc(100vh - 96px);
  overflow: auto;

  &.template-editor-popper--open {
    opacity: 1;
    visibility: visible;

    .template-editor-popper__content {
      opacity: 1;
      visibility: visible;
    }
  }

  .template-editor-popper__content {
    width: 240px;
    opacity: 0;
    visibility: hidden;

    .text-input {
      margin-bottom: 16px;
    }

    .template-editor-popper__input-flex {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      > div:nth-child(2n) {
        margin-left: 8px;
      }
      .outlined-input {
        width: 200px;
        > div {
          > div {
            font-size: 14px;
            padding: 8px 16px 8px 16px;
            outline: none !important;
          }
        }

        fieldset {
          border: 1px solid $tg-border-gray;
        }

        svg {
          color: $tg-black;
          margin-right: 2px;
        }
      }

      .autocomplete-input {
        width: 200px;
        font-size: 14px;

        > div {
          border-color: $tg-border-gray !important;
        }
      }

      .template-editor-popper__input--disabled {
        box-sizing: border-box;
        border-radius: 4px;
        border: 1px solid $tg-border-gray;
        background: $tg-background-gray;
        height: 40px;
        margin-left: 16px;
        width: 200px;
      }
    }

    .template-editor-popper__checkbox-flex {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: $tg-text-gray;
    }

    .template-editor-popper__select-options__option {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      margin-top: 8px;
      color: $tg-black;

      > div {
        margin-bottom: 0px;
      }

      &:first-of-type {
        margin-top: 16px;
      }

      .button {
        margin-left: 16px;
      }
    }

    .template-editor-popper__buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
    }
  }
}

.outlined-input {
  height: 40px;

  > div {
    height: 100%;
  }
}

.outlined-input__option--color {
  padding: 4px 8px;
  border-radius: 2px;
  font-size: 11px;
  text-transform: uppercase;
  line-height: 1;
}
