@import "../../styles/colors.module.scss";

.sortable {
  position: relative;

  .disable-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: map-get($z-index, "sticky");
    background-color: $tg-white;
    opacity: 0.5;
  }
}
