@import "../../styles/colors.module.scss";

.suggested-documents__card {
  background: #fff9f0;
  position: relative;
  border: 2px solid #fff9f0; // to match the table
  padding: 16px;
  margin-bottom: 24px;

  &.suggested-documents__card--closed {
    .suggested-documents__options {
      max-height: 0;
      padding: 0;
      opacity: 0;
      transition: 0.6s cubic-bezier(0, 1, 0, 1);
    }
  }

  .suggested-documents__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      font-size: 16px;
      font-weight: normal;
      margin: 0;
    }

    .icon.nav-arrow-up,
    .icon.nav-arrow-down {
      cursor: pointer;
    }
  }

  .suggested-documents__options {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0 0;
    max-height: 600px;
    overflow-y: hidden;
    opacity: 1;
    transition: 0.6s ease-in-out;

    .suggested-document-option {
      width: calc(50% - 8px);
      min-width: calc(50% - 8px);
      overflow-x: hidden;
      padding: 8px 0;
      cursor: pointer;
      display: flex;
      justify-content: left;
      text-transform: none;
      text-align: inherit;

      &:hover {
        background: inherit;
      }

      .document-icon-container {
        padding: 16px;
        margin-right: 16px;
        background: #ffebc9;
        border-radius: 12px;
        border: 2px solid #ffebc9;
        transition: 0.3s ease-in-out;
        display: flex;
        align-items: center;

        &:hover {
          border-color: $tg-black;
        }
      }

      .suggested-documents__option-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow-x: hidden;
        letter-spacing: normal;

        h3 {
          font-size: 14px;
          font-weight: normal;
          margin: 0;
          width: 100%;
        }

        p {
          font-size: 14px;
          margin: 0;
          color: $tg-text-gray;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 100%;
        }
      }
    }
  }
}
