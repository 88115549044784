@import "../../styles/colors.module.scss";

// standard button specific styles
Button.button {
  display: flex;
  align-items: center;
  min-width: 1px;
  box-sizing: border-box; //prevents size changes when border is enabled
  text-transform: none !important;
  white-space: nowrap;

  .icon {
    margin-right: 8px;
  }

  &.align-left {
    justify-content: flex-start;
  }

  &.align-center {
    justify-content: center;
  }

  &.align-right {
    justify-content: flex-end;
  }

  &.align-space-between {
    justify-content: space-between;
  }

  &.fullWidth {
    width: 100%;
  }

  &.small {
    border-radius: 4px;
    height: 32px;
    min-width: 32px;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 12px;

    .icon {
      font-size: 20px;
      margin-right: 4px;
    }
  }

  &.medium {
    border-radius: 6px;
    height: 40px;
    min-width: 40px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 14px;

    .icon {
      font-size: 24px;
      margin-right: 8px;
    }
  }

  &.large {
    border-radius: 8px;
    height: 56px;
    min-width: 56px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 16px;

    .icon {
      font-size: 24px; // uses the same font-size as medium since some material ui icons become blurry at 28px
      margin-right: 16px;
    }
  }

  &.x-large {
    border-radius: 8px;
    height: 68px;
    min-width: 68px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 18px;

    .icon {
      font-size: 32px;
      margin-right: 24px;
    }
  }

  &.rounded {
    border-radius: 480px; // large radius creates pill shape
  }

  &.noLabel {
    padding: 0;

    .icon {
      margin-right: 0px;
    }

    &.rounded {
      border-radius: 50%;
    }
  }

  .icon {
    color: $tg-white;
  }

  &.black {
    background-color: $tg-black;
    border-color: $tg-black;
    color: $tg-white;

    &:hover {
      background-color: lighten($tg-black, 30%);
      border-color: lighten($tg-black, 30%);
    }
  }

  &.product-background-blue {
    background-color: $tg-product-background-blue;
    border-color: $tg-product-blue;
    color: $tg-product-blue;

    .icon {
      color: $tg-product-blue;
    }

    &:hover {
      background-color: darken($tg-product-background-blue, 5%);
      border-color: darken($tg-product-blue, 5%);
    }
  }

  &.light-green {
    background-color: $tg-light-green;
    border-color: $tg-green;
    color: $tg-green;

    .icon {
      color: $tg-green;
    }

    &:hover {
      background-color: darken($tg-light-green, 5%);
      border-color: darken($tg-green, 5%);
    }
  }

  &.light-red {
    background-color: $tg-light-red;
    border-color: $tg-red;
    color: $tg-red;

    .icon {
      color: $tg-red;
    }
    &:hover {
      background-color: darken($tg-light-red, 5%);
      border-color: darken($tg-red, 5%);
    }
  }

  &.product-blue {
    background-color: $tg-product-blue;
    border-color: $tg-product-blue;
    color: $tg-white;

    &:hover {
      background-color: lighten($tg-product-blue, 5%);
      border-color: lighten($tg-product-blue, 5%);
    }
  }

  &.background-gray {
    background-color: $tg-background-gray;
    border-color: $tg-background-gray;

    .icon {
      color: $tg-black;
    }

    &:hover {
      background-color: darken($tg-background-gray, 5%);
      border-color: darken($tg-background-gray, 5%);
    }
  }

  &.gray {
    background-color: $tg-gray;
    border-color: $tg-gray;

    .icon {
      color: $tg-black;
    }

    &:hover {
      background-color: darken($tg-gray, 5%);
      border-color: darken($tg-gray, 5%);
    }
  }

  &.border-gray {
    background-color: $tg-border-gray;
    border-color: $tg-border-gray;

    .icon {
      color: $tg-black;
    }

    &:hover {
      background-color: darken($tg-border-gray, 5%);
      border-color: darken($tg-border-gray, 5%);
    }
  }

  &.dark-gray {
    background-color: $tg-dark-gray;
    border-color: $tg-dark-gray;
    color: $tg-white;

    &:hover {
      background-color: lighten($tg-dark-gray, 5%);
      border-color: lighten($tg-dark-gray, 5%);
    }
  }

  &.green {
    background-color: $tg-green;
    border-color: $tg-green;
    color: $tg-white;

    &:hover {
      background-color: lighten($tg-green, 5%);
      border-color: lighten($tg-green, 5%);
    }
  }

  &.red {
    background-color: $tg-red;
    border-color: $tg-red;
    color: $tg-white;

    &:hover {
      background-color: lighten($tg-red, 5%);
      border-color: lighten($tg-red, 5%);
    }
  }

  &.yellow {
    background-color: $tg-yellow;
    border-color: $tg-yellow;
    color: $tg-white;

    &:hover {
      background-color: lighten($tg-yellow, 5%);
      border-color: lighten($tg-yellow, 5%);
    }
  }

  // styles for ghost buttons
  &.transparent {
    &.black {
      color: $tg-black;

      .icon {
        color: $tg-black;
      }

      &:hover {
        border-color: lighten($tg-black, 30%);
        color: lighten($tg-black, 30%);

        .icon {
          color: lighten($tg-black, 30%);
        }
      }
    }

    &.product-background-blue,
    &.product-blue {
      color: $tg-product-blue;

      .icon {
        color: $tg-product-blue;
      }

      &:hover {
        border-color: darken($tg-product-blue, 10%);
        color: darken($tg-product-blue, 10%);

        .icon {
          color: darken($tg-product-blue, 10%);
        }
      }
    }

    &.background-gray {
      color: $tg-background-gray;

      .icon {
        color: $tg-background-gray;
      }

      &:hover {
        border-color: darken($tg-background-gray, 10%);
        color: darken($tg-background-gray, 10%);

        .icon {
          color: darken($tg-background-gray, 10%);
        }
      }
    }

    &.gray {
      color: $tg-gray;

      .icon {
        color: $tg-gray;
      }

      &:hover {
        border-color: darken($tg-gray, 10%);
        color: darken($tg-gray, 10%);

        .icon {
          color: darken($tg-gray, 10%);
        }
      }
    }

    &.border-gray {
      color: $tg-border-gray;

      .icon {
        color: $tg-border-gray;
      }

      &:hover {
        border-color: darken($tg-border-gray, 10%);
        color: darken($tg-border-gray, 10%);

        .icon {
          color: darken($tg-border-gray, 10%);
        }
      }
    }

    &.dark-gray {
      color: $tg-dark-gray;

      .icon {
        color: $tg-dark-gray;
      }

      &:hover {
        border-color: lighten($tg-dark-gray, 20%);
        color: lighten($tg-dark-gray, 20%);

        .icon {
          color: lighten($tg-dark-gray, 20%);
        }
      }
    }

    &.light-green,
    &.green {
      color: $tg-green;

      .icon {
        color: $tg-green;
      }

      &:hover {
        border-color: darken($tg-green, 10%);
        color: darken($tg-green, 10%);

        .icon {
          color: darken($tg-green, 10%);
        }
      }
    }

    &.light-red,
    &.red {
      color: $tg-red;

      .icon {
        color: $tg-red;
      }

      &:hover {
        border-color: darken($tg-red, 10%);
        color: darken($tg-red, 10%);

        .icon {
          color: darken($tg-red, 10%);
        }
      }
    }

    &.yellow {
      color: $tg-yellow;

      .icon {
        color: $tg-yellow;
      }

      &:hover {
        border-color: darken($tg-yellow, 10%);
        color: darken($tg-yellow, 10%);

        .icon {
          color: darken($tg-yellow, 10%);
        }
      }
    }

    background-color: transparent !important;
    .icon {
      background-color: transparent !important;
    }
  }

  &.withBorder {
    border-style: solid;
    border-width: 2px;

    &.large,
    &.x-large {
      border-width: 2px;
    }
  }

  &.disabled {
    pointer-events: none;
    color: $tg-gray !important;
    background-color: $tg-background-gray;
    border-color: $tg-gray;

    .icon {
      color: $tg-gray !important;
    }
  }
}

.storybook-button-sizes-wrapper {
  display: flex;

  div.storybook-button-flex-column:nth-of-type(n + 2) {
    margin-left: 16px;
  }
}

.storybook-button-flex-column {
  flex-direction: column;

  button:nth-of-type(n + 2) {
    margin-top: 16px;
  }
}
