@import "../../styles/colors.module.scss";

.highlight-fade {
  transition: background-color 500ms ease-out;

  &.highlight {
    transition: none;
    background-color: $tg-light-orange;
  }
}
