@import "../../styles/colors.module.scss";

.user-profile-icon {
  color: $tg-white;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
  height: 28px;
  width: 28px;
  border-radius: 100%;
}
