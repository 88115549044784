@import "../../../styles/colors.module.scss";

.grid-link-dialog {
  .MuiPaper-root {
    width: 686px;
  }

  .MuiDialogTitle-root {
    border-bottom: 2px solid $tg-border-gray;

    h2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 20px;
      font-weight: normal;
      margin: 0px;
    }

    .title {
      flex-grow: 1;
    }

    .tools {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .buttons {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .MuiDialogContent-root {
    width: 100%;
    padding: 16px;
    box-sizing: border-box;

    .description {
      color: $tg-text-gray;
      font-size: 14px;
    }

    .form {
      margin-top: 32px;
      position: relative;

      .tg-input__control {
        min-height: 48px;
      }

      .MuiButtonBase-root {
        position: absolute;
        top: 32px;
        right: 8px;
        z-index: map-get($z-index, "icon");
      }
    }
  }

  .MuiDialogActions-root {
    padding: 0;
    margin: 16px;

    .pro-tip {
      width: 425px;
    }
    .buttons {
      display: flex;
      justify-content: flex-end;
      flex-grow: 1;
    }
  }
}
