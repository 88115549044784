@import "../../../styles/colors.module.scss";

.MuiDialog-root.booking-request-dialog {
  border-radius: 8px;

  .MuiPaper-root {
    width: 840px;
    position: absolute;
    margin: 0;
    height: calc(100% - 48px);
  }

  .MuiDialogTitle-root {
    padding: 16px 24px;
  }

  h2.MuiTypography-root {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .MuiDialogContent-root {
    border-top: 1px solid $tg-border-gray;
    border-bottom: 1px solid $tg-border-gray;
    padding: 16px 24px;
    overflow-y: auto;
  }

  .MuiDialogActions-root {
    padding: 8px 0;
  }

  .section-header {
    font-size: 18px;
    margin-bottom: 8px;
  }

  .section-subheader {
    font-size: 12px;
    color: $tg-text-gray;
    margin-bottom: 16px;
  }

  .section {
    margin-bottom: 24px;

    .MuiSkeleton-root.read-only--skeleton {
      margin-bottom: 24px;
      max-height: 40px;
    }

    &.status {
      .input-controller-helper {
        display: none;
      }
    }

    &.linked {
      .section-header {
        margin-bottom: 8px;
      }
    }
  }

  .booking-request-input {
    width: 160px;

    .autocomplete-input__control {
      margin-top: 0;
    }
  }

  .linked-fields {
    .linked-field {
      .label {
        display: flex;
        align-items: center;
        font-size: 12px;
        margin-bottom: 8px;

        svg.icon {
          display: inline-block;
          margin-right: 8px;
        }
      }

      .value {
        border: 2px solid $tg-border-gray;
        border-radius: 4px;
        padding: 0 8px;
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        height: 40px;
        font-size: 14px;
        box-sizing: border-box;
      }
    }
  }
}
