@import "../../styles/colors.module.scss";

.pro-tip {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  color: $tg-green;
  background-color: $tg-light-green;
  padding: 16px;
  box-sizing: border-box;

  svg.icon {
    margin-right: 8px;
  }
}
