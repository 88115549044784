@import "../../../../styles/colors.module";

.batch-tags-popper {
  background: $tg-white;
  border: 2px solid $tg-border-gray;
  border-radius: 8px;
  box-shadow: $tg-shadow;
  padding: 16px 16px 16px 24px;
  z-index: map-get($z-index, "menu");

  .batch-tags-popper__content {
    .batch-tags-popper__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      h1 {
        font-size: 16px;
        font-weight: normal;
        color: $tg-black;
        line-height: 1;
        margin: 0;
      }
    }

    .batch-tags-popper__input-flex {
      display: flex;
      margin-bottom: 8px;
      align-items: center;

      .autocomplete-input,
      .select-toggle-menu-header {
        height: 40px;
        width: 200px;
      }

      .select-toggle-menu-header {
        margin-left: 8px;
      }

      .tag-action-input {
        .autocomplete-input {
          width: 108px;
          margin-left: 8px;
        }
      }

      .tag-action-input--disabled {
        background-color: $tg-background-gray;
        border-radius: 4px;
        height: 40px;
        width: 108px;
        margin-left: 8px;
        border: 1px solid $tg-border-gray;
        box-sizing: border-box;
      }

      button.button {
        margin-left: 8px;
      }
    }

    .batch-tags-popper__buttons {
      display: flex;
      justify-content: space-between;
      margin: 16px 40px 0 -12px;
    }
  }
}

.outlined-input__option--color {
  padding: 4px 8px;
  border-radius: 2px;
  font-size: 11px;
  text-transform: uppercase;
  line-height: 1;
}
