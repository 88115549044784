@import "../../styles/colors.module.scss";

.app-banner {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid $tg-product-dark-blue;
  background-color: $tg-product-blue;
  color: $tg-white;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    margin-right: 12px;
  }

  .message {
    text-align: left;
  }

  &.error {
    border: 1px solid $tg-red;
    background-color: $tg-red;
  }
}
