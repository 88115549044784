@import "../../../../../styles/colors.module.scss";

.tml-cell.traveler {
  .entry-type {
    width: 136px;
  }

  .popper-anchor {
    .label {
      width: 100%;

      .name {
        font-size: 14px;
        font-style: normal;
        line-height: 18px;
        letter-spacing: 0em;
        display: inline-block;
        width: 100%;
      }

      .job-title {
        font-size: 12px;
        color: $tg-text-gray;
        width: 100%;
      }
    }
  }
}

.tml-traveler-menu {
  width: 160px;

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }
}
