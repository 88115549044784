@import "../../../../styles/colors.module";

.sink-group-menu {
  .title {
    font-size: 10px;
    text-transform: uppercase;
    margin-bottom: 16px;
    color: $tg-text-gray;
  }

  .groups {
    margin-bottom: 24px;
    .group {
      font-size: 14px;
      color: $tg-text-gray;
      margin-bottom: 4px;
      cursor: pointer;
      padding: 8px;
      border-radius: 4px;

      &:hover,
      &.active {
        background-color: $tg-product-background-blue;
      }
    }
  }
}
