@import "../../styles/colors.module.scss";

.toggle-switch {
  background-color: $tg-border-gray;
  border-radius: 100px;
  height: 24px;
  width: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;

  .handle {
    position: absolute;
    left: 2px;
    top: 2px;
    height: 20px;
    width: 20px;
    display: inline-flex;
    align-items: center;
    border-radius: 100px;
    background-color: $tg-white;
    transition: left 50ms linear;
  }

  &.on {
    background-color: $tg-green;
    .handle {
      left: 18px;
    }

    &.small {
      .handle {
        left: 12px;
      }
    }
  }

  &.small {
    height: 14px;
    width: 24px;
    border-radius: 50px;

    .handle {
      height: 10px;
      width: 10px;
      left: 2px;
      top: 2px;
    }
  }
}
