@import "../../styles/colors.module.scss";

@mixin transition-delay {
  transition: max-height 0s 0.5s linear, max-width 0s 0.5s linear,
    opacity 0s 0.5s linear, margin 0s 0.5s linear, min-height 0s 0.5s linear,
    min-width 0s 0.5s linear;
  animation-timing-function: linear;
}

@mixin transition-delay-size {
  @include transition-delay;
  max-height: 500px;
  max-width: 500px;
}

@mixin transition-instant-margin {
  transition: margin 0s 0s linear;
  animation-timing-function: linear;
}

@mixin transition-instant-collapsed {
  max-height: 0;
  max-width: 0;
  opacity: 0;
  margin: 0;
  min-height: 0;
  min-width: 0;
  overflow: hidden;
  transition: all 0s 0s linear;
  animation-timing-function: linear;
}

.app-sidebar {
  box-sizing: border-box;
  width: 280px;
  min-width: 280px;
  height: 100vh;
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
  z-index: map-get($z-index, "app");
  transition: all 0.6s ease-in-out;
  animation-timing-function: ease-in-out;

  a {
    outline: none;
  }

  .gray-background {
    box-sizing: border-box;
    background-color: $tg-background-gray;
    height: 100%;
    width: 248px;
    min-width: 248px;
    padding: 16px 16px 0 16px;
    transition: all 0.6s ease-in-out;
    animation-timing-function: ease-in-out;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    box-sizing: border-box;

    .main {
      width: 100%;
    }

    .footer {
      width: 100%;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
    }
  }

  .app-sidebar-header {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    height: 32px;
    margin-top: 4px;
    margin-bottom: 28px;
    @include transition-delay;

    .company-logo {
      margin-left: 4px; // prevents icon from shifting when collapsing
      width: 32px;
      height: 32px;
    }

    span.tg-letter-mark {
      display: flex;
      margin-left: 8px;
      @include transition-delay-size;

      img {
        width: 88px;
      }
    }
  }

  .app-sidebar-search {
    margin-bottom: 24px;
    @include transition-delay-size;
  }

  .app-sidebar-links {
    margin-bottom: 24px;
    @include transition-delay();
  }

  .app-sidebar-grids-nav {
    padding: 0;
    margin-bottom: 24px;
    @include transition-delay-size;

    .header {
      font-size: 10px;
      color: $tg-text-gray;
      text-transform: uppercase;
      margin-bottom: 8px;
    }

    .grid-link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      border-left: 2px solid transparent;
      margin: 0 -16px;
      padding: 4px 4px 4px 16px;

      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow-x: hidden;
      }

      &.grid-link--active {
        border-color: $tg-product-blue;

        .grid-link-label .icon {
          color: $tg-yellow;
        }
      }

      .grid-link-label {
        display: flex;
        align-items: flex-start;
        overflow-x: hidden;

        .icon {
          padding-top: 0.5px;
          margin-right: 6px;
        }
      }

      .grid-card-menu-trigger {
        margin-left: 4px;
        opacity: 0; // don't use display none, must be in dom for popper anchorEl
        pointer-events: none;
      }

      &:hover,
      &.hover {
        background: $tg-border-gray;

        .grid-link-label .icon {
          color: $tg-yellow;
        }

        .grid-card-menu-trigger {
          opacity: 1;
          pointer-events: all;
        }
      }
    }

    a {
      color: $tg-black;
      text-decoration: none;

      font-size: 14px;
    }
  }

  .app-sidebar-link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    text-decoration: none;
    color: $tg-dark-gray;
    line-height: 100%;
    padding: 8px;
    margin-bottom: 8px;
    border-radius: 4px;
    height: 40px;
    box-sizing: border-box;
    @include transition-delay();

    span.label {
      @include transition-delay-size;
    }

    /* icon SVGs have arbitrary whitespace built in...*/
    &.tg-collection {
      padding-left: 6px;
    }

    .icon-wrapper {
      display: block;
      width: 24px;
      overflow: hidden;
      margin-right: 16px;
      @include transition-delay();

      /* icon SVGs have arbitrary whitespace built in...*/
      .icon.home {
        margin-left: -3px;
      }
      .icon.people {
        margin-left: -1px;
      }
      .icon.reports {
        margin-left: -2px;
      }
    }

    &.active,
    &:hover {
      background-color: $tg-product-light-blue;
      color: $tg-product-blue !important;

      svg.icon {
        fill: $tg-product-blue;
      }
    }
  }

  .divider {
    height: 1px;
    min-height: 1px;
    background-color: $tg-border-gray;
    margin: 0px 8px 16px 8px;
    @include transition-delay-size;
  }

  .expander-button {
    display: block;
    position: absolute;
    top: 16px;
    right: 32px;
    z-index: -1;
    transition: transform 0.3s ease-in;

    .button {
      background: $tg-background-gray !important;
      border-color: $tg-background-gray !important;
      min-width: auto;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      .icon {
        opacity: 0;
        transition: none;
      }
    }
  }

  &:hover {
    .expander-button {
      transform: translateX(24px);

      .button {
        .icon {
          opacity: 1;
          transition: opacity 0.3s ease-in;
          transition-delay: 0.5s;
        }
      }
    }
  }

  .create-grid-button {
    border-top: 1px solid $tg-border-gray;
    background: $tg-background-gray;
    position: sticky;
    margin: 0 -16px;
    width: calc(100% + 32px);
    bottom: 0;
    left: 0;
    opacity: 1;

    .button {
      padding-left: 0 !important;
      font-size: 14px;

      .icon {
        color: $tg-product-blue !important;
      }
    }

    @include transition-delay;
  }

  &.collapsed {
    width: 104px;
    min-width: 104px;

    .app-sidebar-header {
      display: block;

      @include transition-instant-margin;
      .company-logo {
        display: block;
        margin: auto;
        transition: width 0.1s 0.1s linear, min-width 0.1s 0.1s linear,
          margin 0.1s 0.1s linear;
      }
      span.tg-letter-mark {
        height: 0;
        width: 0;
        opacity: 0;
        transition: width 0s 0.2s linear, height 0s 0.2s linear,
          opacity 0.1s 0s linear;
      }
    }
    .app-sidebar-search,
    .app-sidebar-grids-nav,
    .divider {
      @include transition-instant-collapsed;
    }

    .app-sidebar-link {
      margin-bottom: 24px;
      @include transition-instant-margin;

      span.label {
        @include transition-instant-collapsed;
      }
      .icon-wrapper {
        margin: 0px 0px 0px 2px;
        @include transition-instant-margin;
      }
    }

    .app-sidebar-links {
      margin-bottom: 0px;
      @include transition-instant-margin;
    }

    .gray-background {
      width: 72px;
      min-width: 72px;
    }

    .inner {
      align-items: center;
      width: 40px;
      padding-bottom: 16px;
    }

    .create-grid-button {
      pointer-events: none;
      opacity: 0;

      @include transition-instant-collapsed;
    }
  }
}

.app-sidebar:not(.collapsed) {
  .inner {
    .footer {
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-start;
      padding-bottom: 16px;
      @include transition-delay;
    }
  }
}
