@import "../tile.scss";

.tml-tile {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 8px 8px 16px 8px;
  }

  .tml-entries {
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    padding: 0px 8px;
    box-sizing: border-box;
    font-size: 14px;

    .header {
      background-color: $tg-background-gray;
      padding: 4px 12px;
      margin: 0 0 12px 0;
      line-height: 100%;
      border-radius: 6px;
      width: 100%;
      border-bottom: 12px;
      box-sizing: border-box;
    }

    .travelers {
      margin-bottom: 16px;
      padding: 0 1px;

      .traveler-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;

        .entry-type {
          text-align: right;
          color: $tg-red;
          margin-left: 8px;

          &.isInbound {
            color: $tg-green;
          }
        }
      }
    }
  }
}
