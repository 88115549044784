@import "../../../../../styles/colors.module.scss";

.tml-edit-menu {
  width: 160px;

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }
}
