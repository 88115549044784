@import "../../styles/colors.module.scss";

.user-profile-icons {
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  .user-profile-icon {
    margin-right: 4px;
  }

  .more-users-count {
    background-color: $tg-light-gray;
    font-size: 10px;
    display: flex;
    width: 28px;
    height: 28px;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    span {
      display: inline-block;
    }
  }
}
