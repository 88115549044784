@import "../../styles/colors.module.scss";

.accordion-group {
  .accordion-section {
    margin-bottom: 16px;

    &.open,
    &:hover {
      .header {
        cursor: pointer;
        border: 2px solid $tg-product-blue;
        background-color: $tg-product-background-blue;
        color: $tg-product-blue;
      }
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 8px;
      border: 2px solid $tg-background-gray;
      box-sizing: border-box;
      background-color: $tg-background-gray;
      color: $tg-text-gray;
      padding: 24px 16px;
      transition: all 0.3s ease-in-out;
      margin-bottom: 16px;

      .title {
        font-size: 18px;
      }

      .expand-button {
        display: flex;
        align-items: center;
        font-size: 14px;
      }
    }

    .content {
      display: none;
    }

    &.open {
      .content {
        display: block;
      }
    }
  }
}
