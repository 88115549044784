@import "../tile.scss";

.quick-reports-tile {
  a {
    text-decoration: none;
  }

  .header {
    padding: 0px 8px;
  }
  .description {
    padding: 0px 8px;
  }

  .report-link-container {
    color: $tg-product-dark-blue;
    border: 2px solid $tg-border-gray;
    margin: 0 8px 8px;
    border-radius: 4px;
    transition: background-color 0.3s ease-in-out;

    &:hover {
      background-color: $tg-background-gray;
    }

    .link-header {
      padding: 8px;
    }

    .link-description {
      color: $tg-text-gray;
      padding: 0px 8px 8px 8px;
    }
  }
}
