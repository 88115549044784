@import "../../styles/colors.module.scss";

#grid-reports-page {
  .reports-page-wrapper {
    margin: auto;
    padding: 0 0 48px;

    .reports-page-header {
      display: flex;
      justify-content: space-between;
      padding: 0 16px;
      height: 64px;
      align-items: center;
      box-sizing: border-box;
      border-bottom: 1px solid $tg-border-gray;

      .reports-page-header__branding {
        display: flex;
        align-items: flex-start;
        margin-left: 8px;

        > img:first-child {
          margin-top: -2px;
          margin-right: 6px;
        }

        img {
          height: 32px;
        }
      }
    }

    .reports-page-content {
      padding: 24px 80px 0;

      @media (max-width: 768px) {
        padding: 24px 24px 0;
      }

      h1 {
        font-size: 24px;
        font-weight: normal;
        margin-bottom: 32px;
      }

      h2 {
        font-size: 18px;
        font-weight: normal;
        color: $tg-text-gray;
        margin-bottom: 24px;
      }
    }
  }

  @media print {
    .reports-page-wrapper {
      margin: 0;
      padding: 0;
      max-width: none;
      height: 11in;

      .reports-page-content {
        padding-left: 0;
        padding-right: 0;
      }

      button,
      i {
        display: none;
      }
    }
  }
}
