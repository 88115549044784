@import "../../../styles/colors.module.scss";

.quick-add-dialog {
  border-radius: 8px;

  &.quick-add-dialog-standard {
    .paper {
      position: absolute;
      right: 24px;
      top: 24px;
      margin: 0;
    }

    .paper-scroll-paper {
      max-height: calc(100% - 48px);
    }

    .paper-width-sm {
      max-width: 680px;
    }

    .dialog-title {
      padding: 16px 24px;
    }

    .dialog-content {
      border-top: 1px solid $tg-border-gray;
      border-bottom: 1px solid $tg-border-gray;
      display: flex;
      padding: 0;
      position: relative;
      overflow-y: auto;
    }

    .dialog-actions {
      margin: 8px 16px;
    }
  }

  // renders differently for segmented trips
  &.quick-add-dialog-large {
    .paper {
      @media (max-width: 1280px) {
        position: absolute;
        right: 24px;
        top: 24px;
        margin: 0;
      }
    }

    .paper-scroll-paper {
      display: flex;
      flex-direction: column;
      max-height: calc(100% - 48px);
    }

    .paper-width-sm {
      max-width: none;
    }

    .dialog-content {
      border-top: 1px solid $tg-border-gray;
      display: flex;
      padding: 0;
      overflow-y: auto;
      @media (max-width: 1280px) {
        flex-direction: column;
      }
    }
  }

  .quick-add-dialog__title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      font-size: 20px;
      font-weight: normal;
      margin: 0;
    }

    .quick-add-dialog__lock-indicator {
      color: $tg-red;
      display: flex;
      align-items: center;
      font-size: 11px;
      margin-left: 8px;
      padding-bottom: 4px;

      .icon {
        margin-right: 4px;
        padding-bottom: 2px;
      }
    }
  }

  .quick-add-dialog__button-group {
    display: flex;
    align-items: center;

    button.button:nth-child(n + 2) {
      margin-left: 8px;
    }
  }

  .quick-add-dialog__form {
    width: 344px;
    padding: 16px 24px;

    > div {
      margin-bottom: 8px;
    }

    .date-input,
    .text-input,
    .time-input {
      height: 64px;
    }

    .time-input {
      width: 128px;
    }

    @media (min-width: 1280px) {
      overflow-y: auto;
    }

    .autocomplete-input,
    .autocomplete-input-grouped,
    .traveler-input {
      height: inherit;
      min-height: none;
    }
  }

  .quick-add-dialog__form-header {
    font-size: 20px;
    font-weight: normal;
    margin: 0;
  }

  .quick-add-dialog__segment-tracker {
    padding: 16px 24px;
    border-left: 1px solid $tg-border-gray;

    div.quick-add-dialog__segment-wrapper {
      max-width: 264px;
      border: 2px solid $tg-border-gray;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 4px 8px;
      border-radius: 4px;
      box-sizing: border-box;
      height: 64px;

      .remove-button {
        margin-left: 8px;
      }

      &:nth-child(n + 2) {
        margin-top: 8px;
      }

      div.quick-add-dialog__segment-text {
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        justify-content: space-between;
        overflow: hidden;

        span {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .quick-add-dialog__segment-selector {
    padding: 16px 24px 8px;
    border-left: 1px solid $tg-border-gray;
    border-right: 1px solid $tg-border-gray;
    width: 344px;
    min-width: 344px;
    display: flex;
    flex-direction: column;

    div.segment-selector-date-group {
      margin-bottom: 8px;
    }

    h3.segment-selector-date-header {
      font-size: 16px;
      font-weight: normal;
      margin-top: 0;
      margin-bottom: 16px;
      text-align: center;
      color: $tg-text-gray;
    }

    .segment-selector-button-group {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      button {
        height: auto;
        min-height: 40px;
      }

      .MuiButton-label {
        white-space: normal;
      }
    }

    @media (max-width: 1280px) {
      border-left: none;
      border-right: none;
      border-top: 1px solid $tg-border-gray;
      border-bottom: 1px solid $tg-border-gray;
    }
  }

  .quick-add-dialog__form-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .quick-add-dialog__gds-info {
      display: flex;
      align-items: center;

      span {
        color: $tg-green;
        font-size: 14px;
        display: inline-block;
        line-height: 100%;
        padding-top: 2px;
      }
    }
  }

  .autocomplete-input--filled {
    z-index: inherit;
  }

  .quick-add-dialog__content-flex {
    display: flex;
    justify-content: space-between;
    > div {
      width: calc(50% - 4px);
    }
  }

  .quick-add-dialog__content-helper-label {
    font-size: 12px;
    color: $tg-text-gray;
    margin: -4px 0 8px;
  }

  .input--text {
    margin-top: 8px;
  }

  .quick-add-dialog__actions {
    display: flex;
    justify-content: space-between;
  }
}
