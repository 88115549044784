@import "../../../styles/colors.module.scss";

.confirmation-warning-dialog {
  .paper {
    padding: 24px 16px;
  }

  .confirmation-warning-dialog__title {
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      color: $tg-red;
      font-size: 20px;
      font-weight: normal;
      margin: 0;
    }
  }

  .confirmation-warning-dialog__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .confirmation-warning-dialog__title {
      padding: 0;
      text-align: center;
    }
  }

  .confirmation-warning-dialog__body {
    color: $tg-text-gray;
    text-align: center;
  }

  .confirmation-warning-dialog__actions {
    display: flex;
    justify-content: center;

    .button {
      width: 160px;
    }
  }
}
