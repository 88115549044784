@import "../../../../styles/colors.module.scss";
@import "../tile.scss";

.grid-big-tile__inner {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.tml-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;

  .tml-settings {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .setting {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-right: 32px;

      .value {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 8px;
        font-size: 16px;
        color: $tg-text-gray;
      }
    }

    .grid-name {
      font-size: 18px;
      margin-right: 32px;
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;

    button {
      margin-left: 8px;
    }
  }
}

#tml-big-tile {
  padding: 24px 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  > div.title {
    font-size: 20px;
    margin-bottom: 32px;
  }

  #tml-entries-group {
    .download-button {
      cursor: pointer;
      .app-icon-frame {
        padding: 8px;
      }
    }
  }

  .invalid-settings {
    height: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .description {
      font-size: 14px;
      line-height: 18px;
      color: $tg-text-gray;
      margin-bottom: 24px;
    }
  }
}

.tml-settings-dialog {
  h2.MuiTypography-root {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .MuiPaper-root {
    width: 685px;
  }
}
