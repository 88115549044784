@import "../../../styles/colors.module.scss";

.remove-button {
  .button {
    &:hover {
      .icon {
        color: $tg-red !important;
      }
    }
  }
}
