@import "../../../styles/colors.module.scss";

.create-document-option-wrapper {
  display: flex;
  align-items: center;

  .create-document-option__label {
    white-space: nowrap;
    max-width: 300px;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  .create-document-option__label--beta {
    margin-left: 8px;
    border: 1px solid $tg-product-blue;
    border-radius: 4px;
    font-size: 11px;
    padding: 2px 4px;
    line-height: 1;
    color: $tg-product-blue;
  }
}
