@import "../../../styles/colors.module";

.input-controller {
  .input-controller-label {
    display: flex;
    align-items: center;
    height: 24px;
    font-size: 12px;
    color: $tg-black;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .input-controller-label-helper-text {
    font-size: 12px;
    color: $tg-text-gray;
    margin-bottom: 8px;
  }

  .input-controller-input {
    .MuiFilledInput-input {
      padding: 8px;
      min-height: 24px;
    }

    .MuiFilledInput-multiline {
      padding: 0;
    }

    .traveler-input {
      margin-bottom: 0;
      padding-bottom: 2px;
      min-height: unset;
    }

    .autocomplete-input--filled {
      min-height: 40px;
      padding: 4px 8px;
      margin-bottom: 0;
    }

    .tg-input__value-container {
      padding-left: 8px;
      padding-right: 8px;
    }

    .time-input {
      label {
        visibility: hidden;
      }
    }
  }

  .input-controller-helper {
    display: flex;
    align-items: center;
    height: 16px;
    font-size: 12px;
    color: $tg-text-gray;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.input-controller-helper--error {
      color: $tg-red;
    }
  }

  &.error {
    div.MuiInputBase-root {
      border-color: $tg-red;
    }
  }
}
