@import "../../styles/colors.module";

.date-range-select {
  position: relative;

  &.disabled {
    .date-arrows {
      .date-arrow {
        cursor: default;

        &:hover {
          background-color: inherit;
        }
      }
    }
  }

  .date-arrows {
    position: absolute;
    display: flex;
    height: 36px;
    align-items: center;
    justify-content: flex-start;
    z-index: map-get($z-index, "icon");
    top: 2px;
    left: 249px;

    &.start {
      left: 101px;
    }

    .date-arrow {
      cursor: pointer;
      height: 100%;
      display: flex;
      width: 18px;
      overflow: hidden;
      align-items: center;
      height: 100%;

      &:hover {
        background-color: $tg-background-gray;
      }

      svg {
        display: block;
        margin-left: -3px;
      }
    }
  }

  .DateRangePicker {
    div.DateInput {
      width: auto;

      &.DateInput_1 {
        margin-right: 8px;
      }
    }

    div.DateRangePickerInput__disabled,
    div.DateInput__disabled {
      background: none;
    }

    input.DateInput_input__disabled {
      font-style: inherit;
    }

    input.DateInput_input {
      font-size: 14px;
      font-weight: inherit;
      padding: 8px 16px;
      width: 140px;
      height: 40px;
      box-sizing: border-box;
      border: 2px solid $tg-border-gray;
      border-radius: 4px;
      line-height: 100%;
    }

    .DateInput_fang {
      display: none;
    }

    .DateRangePickerInput_arrow {
      display: none;
    }
  }
}

div.DateRangePicker_picker {
  margin-top: -20px;
  z-index: map-get($z-index, "modal") + map-get($z-index, "select-control");
  border-radius: 8px;

  div.DayPicker {
    border: 2px solid $tg-border-gray;
    border-radius: 8px;
    box-shadow: $tg-shadow;

    .DayPickerNavigation {
      .month-nav {
        position: absolute;
        top: 21px;

        &.prev {
          left: 22px;
        }

        &.next {
          right: 22px;
        }
      }
    }
  }

  .CalendarMonthGrid {
    table.CalendarMonth_table {
      td.CalendarDay {
        border: none;
        background: none;
        vertical-align: middle;

        .day-circle {
          height: 90%;
          width: 90%;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid $tg-white;
          border-radius: 50%;

          span {
            line-height: 100%;
          }
        }
      }

      td.CalendarDay__selected_start,
      td.CalendarDay__selected_end,
      td.CalendarDay__hovered_span,
      td.CalendarDay__selected_span {
        .day-circle {
          border-color: $tg-product-blue;
        }
      }

      td.CalendarDay__hovered_span,
      td.CalendarDay__selected_span {
        .day-circle {
          border-color: $tg-product-light-blue;
          background-color: $tg-product-light-blue;
          color: $tg-product-blue;
        }
      }

      td.CalendarDay__selected_start,
      td.CalendarDay__selected_end {
        .day-circle {
          background-color: $tg-product-blue;
        }
      }
    }
    .CalendarMonth_caption {
      strong {
        font-weight: normal;
      }
    }
  }
}
