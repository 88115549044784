@import "../../styles/colors.module.scss";

.custom-table {
  width: 100%;

  .mui-data-table-wrapper {
    overflow: hidden;
  }

  &.custom-table--card {
    &.custom-table--loading > .custom-table--loading-container {
      border-radius: 8px;
      top: 24px;
    }

    .mui-data-table-wrapper {
      border: 2px solid $tg-black;
      border-radius: 8px;
      margin-left: 0;
      margin-top: 24px;

      > div {
        margin-left: 0;

        > div:first-child {
          padding: 8px 16px;
          background-color: $tg-background-gray;
          border-bottom: 2px solid $tg-black;
        }

        tbody {
          tr {
            height: 48px;
            &:last-child {
              td {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }
}
