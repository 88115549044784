@import "../../styles/colors.module.scss";

.grid-card-popper {
  background: $tg-white;
  border-radius: 4px;
  box-shadow: $tg-shadow;
  margin-bottom: 4px;
  padding: 4px;
  border: 2px solid $tg-border-gray;
  z-index: map-get($z-index, "menu");

  .popper-row {
    display: flex;
    align-items: center;
    font-size: 14px;
    height: 32px;
    padding: 0 12px 0 8px;
    border-radius: 4px;
    cursor: pointer;

    .icon {
      margin-right: 8px;
    }

    &:hover {
      background-color: $tg-background-gray;
    }
  }
}
