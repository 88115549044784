@import "../../styles/colors.module.scss";

.grid-big-tile-dialog {
  .paper-width-sm {
    max-width: none;
  }

  .paper-scroll-paper {
    display: block;
    min-height: calc(100% - 56px);
    max-height: calc(100% - 56px);
    height: calc(100% - 56px);
    min-width: calc(100% - 56px);
    max-width: calc(100% - 56px);
    width: calc(100% - 56px);
  }

  .MuiDialogTitle-root {
    padding: 0 !important;
  }

  .MuiDialogContent-root {
    border-top: 1px solid $tg-border-gray;
    display: flex;
    justify-content: center;
    padding: 0 16px;
    position: relative;
    overflow-y: auto;
  }
}

.grid-big-tile__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px 8px 16px;

  h1 {
    font-size: 16px;
    font-weight: normal;
    margin: 0;
  }
}
