@import "../../styles/colors.module.scss";

.travel-filter-menu {
  display: inline-block;

  // style overriding to match the trip type drop down on dashboard
  .travel-filter-trigger {
    margin-left: 8px;
    button.button {
      border-color: $tg-border-gray !important;
      border-radius: 4px;
      margin-left: 0 !important;
      color: $tg-black !important;

      &:hover {
        background-color: $tg-background-gray !important;
      }

      &.product-blue {
        color: $tg-white !important;
        border-color: $tg-product-blue !important;

        &:hover {
          background-color: lighten($tg-product-blue, 5%) !important;
        }
      }
    }
  }
}

#travel-filter-popper {
  background: $tg-white;
  border: 2px solid $tg-border-gray;
  border-radius: 4px;
  box-shadow: $tg-shadow;
  margin-top: 8px;
  min-width: 536px;
  width: 572px; // required to prevent the popper from calcing incorrect offset position
  z-index: map-get($z-index, "menu");
  overflow-y: visible;

  .travel-filter {
    max-height: calc(50vh);
    overflow-y: auto;
  }

  .travel-filter-type {
    padding: 8px 20px 24px;
    margin-bottom: 0;
  }

  .buttons {
    background: white;
    bottom: 0;
    border-top: 1px solid $tg-border-gray;
    box-sizing: border-box;
    padding: 16px;
    position: sticky;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    button.button:nth-child(n + 2) {
      margin-left: 8px;
    }

    .search-count {
      color: $tg-black;
      text-align: left;
      flex-grow: 1;
    }
  }
}
