@import "../../../styles/colors.module.scss";

.empty-table-wrapper {
  padding: 16px 16px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .empty-table__title {
    font-size: 16px;
    margin-bottom: 24px;
  }

  .empty-table__content {
    .empty-table__image {
      display: block;
      margin: auto;
      width: 120px;
      margin-bottom: 24px;
    }

    .empty-table__display-text {
      color: $tg-text-gray;
      text-align: center;
      font-size: 16px;
      margin-bottom: 24px;
      display: block;
    }

    .empty-table__description {
      font-size: 14px;
      text-align: center;
      color: $tg-text-gray;
      display: block;
      margin-top: 24px;
    }

    button.button {
      margin: auto;
    }
  }
}
