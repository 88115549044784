@import "../../../../styles/colors.module.scss";

.image-block {
  min-height: 24px;

  .image-block__upload-trigger {
    cursor: pointer;
    display: flex;
    align-items: center;

    .app-tooltip-wrapper {
      flex-grow: 1;
    }

    img {
      width: 100%;
      border-radius: 4px;
    }

    button {
      width: 100%;
      border-radius: 4px;
    }
  }

  &.image-block--align-left {
    margin-right: auto;

    span {
      margin-right: auto;
    }
  }

  &.image-block--align-center {
    margin: auto;

    span {
      margin: auto;
    }
  }

  &.image-block--align-right {
    margin-left: auto;

    span {
      margin-left: auto;
    }
  }

  .image-block__map {
    display: block;
    height: 400px;
    width: 100%;
    border: 2px solid $tg-border-gray;
    border-radius: 4px;
    box-sizing: border-box;
    background: $tg-product-background-blue;
  }

  .image-block__map--empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
    width: 100%;
    border-radius: 4px;
    background: $tg-product-background-blue;

    .icon {
      color: $tg-product-blue;
      height: 48px;
      width: 48px;
    }
  }
}

.autocomplete-input--filled {
  z-index: inherit;
  background: $tg-white !important;

  .autocomplete-input {
    height: auto;
  }
}
