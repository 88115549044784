@import "../../../../styles/colors.module";

.sink-sort-menu {
  min-width: 424px;

  .title {
    font-size: 16px;
    color: $tg-text-gray;
    margin-bottom: 8px;
  }

  .form {
    display: flex;
    align-items: top;
    justify-content: flex-start;

    .autocomplete-input--filled {
      min-height: auto;
      display: flex;
      align-items: center;
      height: 40px;
      width: 248px;
      padding: 4px 8px;
      margin: 0;

      .autocomplete-input {
        width: 100%;
      }

      .autocomplete-input__control {
        margin-top: 0;
      }
    }

    .from {
      font-size: 14px;
      height: 40px;
      box-sizing: border-box;
      border-radius: 4px;
      margin: 0 8px;
      display: flex;
      align-items: center;
      color: $tg-product-blue;

      background-color: $tg-background-gray;
      color: $tg-product-blue;
      padding: 0px 16px;
      div {
        text-align: center;
      }
    }
  }
}
