@import "../../../styles/colors.module.scss";

.convert-csv-dialog {
  .MuiDialogTitle-root {
    border-bottom: 1px solid $tg-border-gray;

    h1 {
      font-size: 20px;
      font-weight: normal;
      margin: 0;
    }

    h2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .MuiDialogContent-root {
    padding: 16px 24px;
  }

  .description {
    margin: 16px 0;
  }

  textarea {
    max-height: 96px;
    min-height: 96px;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .import-error {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: $tg-red;
      svg.icon {
        margin-right: 4px;
      }
      span {
        display: block;
        line-height: 100%;
      }
    }
  }
}
