@import "../../styles/colors.module.scss";

@media print {
  .document-builder {
    .document-builder__menu-bar {
      display: none !important;
    }
  }
}

.document-builder {
  background: $tg-white;
  padding: 0 0 80px;
  margin: 0;
  min-height: 100vh;

  &.document-builder__sharelink {
    padding: 32px 0 40px;
  }

  .document-builder__menu-bar {
    position: sticky;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    background: $tg-white;
    top: 0;
    border-bottom: 1px solid $tg-border-gray;
    width: 100%;
    margin-bottom: 64px;
    height: 64px;
    z-index: map-get($z-index, "sticky");
    padding-left: 16px;

    .document-builder__menu__backlink {
      align-items: center;
      cursor: pointer;
      display: flex;
      margin-left: 8px;
      overflow: hidden;

      .icon {
        margin-right: 8px;
      }

      span {
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .document-builder__menu__button-group {
      align-items: center;
      display: flex;
      margin: 0 16px;

      span:nth-child(n + 2) {
        margin-left: 8px;
      }
    }
  }

  .add-block-menu--last {
    padding: 12px 0;

    position: relative;
    max-width: 1000px;
    margin: auto;
    display: flex;

    .add-block-menu {
      position: absolute;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      left: 8px;
    }
  }

  .format-block-menu {
    width: -moz-fit-content;
  }

  .document-builder__block {
    position: relative;
    background-color: transparent;
    border: 2px solid transparent;
    border-radius: 12px;
    box-sizing: border-box;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 64px;
    align-items: flex-start;

    .custom-table,
    .object-table {
      width: 100%;
    }

    .add-block-menu {
      position: absolute;
      top: -56px;
      left: 8px;
    }

    .text-block-wrapper {
      width: 100%;
    }

    .mui-data-table-wrapper {
      background-color: $tg-white;
      margin: 0;
    }

    .add-block-menu,
    .format-block-menu,
    .move-block-menu,
    .multi-block-menu {
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s ease-in-out;

      // vertical spacers added to prevent hover from deactivating between block and menu gap
      &:before {
        content: "";
        background: transparent;
        position: absolute;
        width: 0;
        height: 16px;
        display: block;
        top: -18px;
        left: -2px;
        width: calc(100% + 4px);
      }

      &:after {
        content: "";
        background: transparent;
        position: absolute;
        width: 0;
        height: 16px;
        display: block;
        bottom: -18px;
        left: -2px;
        width: calc(100% + 4px);
      }
    }

    .add-block-menu {
      &.add-block-menu--hidden {
        visibility: hidden !important;
        opacity: 0 !important;
      }
    }

    .move-block-menu {
      &.move-block-menu--hidden {
        visibility: hidden !important;
        opacity: 0 !important;
      }
    }

    &:first-child {
      .move-block-menu__option--move-up {
        .button {
          pointer-events: none;
          .icon {
            color: $tg-text-gray;
          }
        }
      }
    }

    &:last-child {
      .move-block-menu__option--move-down {
        .button {
          pointer-events: none;
          .icon {
            color: $tg-text-gray;
          }
        }
      }
    }

    &:hover {
      background-color: $tg-background-gray;

      .add-block-menu,
      .move-block-menu {
        visibility: visible;
        opacity: 1;
      }
    }

    .text-block-wrapper {
      position: relative;

      &:hover {
        .multi-block-menu {
          visibility: visible;
          opacity: 1;
        }

        &:focus-within {
          .format-block-menu {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }

    .attachment-block-wrapper,
    .image-block-wrapper {
      position: relative;
      padding: 8px 0;

      .multi-block-menu {
        display: none;
      }

      &:hover {
        .format-block-menu,
        .multi-block-menu {
          display: flex;
          visibility: visible;
          opacity: 1;
        }
      }
    }

    .page-break-wrapper {
      display: flex;
      align-items: center;
      padding: 8px 0;

      .page-break-line {
        height: 1px;
        background: $tg-border-gray;
        width: 100%;
      }

      span {
        padding: 0 16px;
        white-space: nowrap;
        display: inline-block;
      }
    }

    .multi-block-wrapper {
      display: flex;
      padding: 8px 0;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;

      > div:nth-child(n + 2) {
        margin-left: 8px;
      }

      .attachment-block-wrapper,
      .image-block-wrapper {
        padding: 0;
        width: 100%;
      }

      .attachment-block-wrapper,
      .text-block-wrapper {
        box-sizing: border-box;
        border: 2px solid transparent;
        border-radius: 4px;

        &:hover {
          border-color: $tg-border-gray;
        }

        &:focus-within {
          border-color: $tg-product-blue;
        }
      }

      .text-block-wrapper {
        margin: 0 -8px;
        padding: 0 8px;
      }
    }

    @media (max-width: 480px) {
      .multi-block-wrapper {
        flex-direction: column;
      }
    }
  }

  @media (max-width: 768px) {
    .document-builder__block {
      padding: 0 16px;
    }
    .document-builder__footer {
      padding: 0 16px;
    }
  }

  .document-builder__footer {
    max-width: 1000px;
    padding: 0 64px;
    margin: 16px auto;
    display: flex;
    justify-content: flex-end;

    .document-builder__site-link {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .document-builder__branding-text {
      margin-right: 8px;
    }

    .document-builder__branding {
      display: flex;
      align-items: center;
      padding: 0;
      transition: 0.3s ease-in-out;
      position: relative;
      align-self: flex-start;

      span {
        font-size: 14px;
      }

      .logo {
        display: flex;
        align-items: center;
        position: relative;
        top: 0;
        transition: all 0.3s ease-in-out;

        .logo__icon {
          height: 32px;
          margin-top: -2px;
        }

        img:nth-child(n + 2) {
          margin-left: 6px;
          height: 32px;
        }
      }
    }
  }

  &.document-builder__view-mode {
    background: $tg-white;
    min-height: 100vh;

    .page-break-wrapper {
      display: none;
    }

    & > .document-builder__block:first-child {
      margin-top: -80px;
    }

    .file-uploader,
    .table-title,
    .text-block-wrapper textarea {
      pointer-events: none;
    }

    @media (max-width: 480px) {
      .table-title__name {
        .text-input {
          input {
            font-size: 16px;
          }
        }
      }
    }

    .image-block-wrapper {
      .image-block__map--empty {
        display: none;
      }

      .autocomplete-input--filled {
        display: none;
        background: transparent !important;
        pointer-events: none;

        div,
        label {
          color: $tg-black !important;
        }
      }
    }

    .schedule-block-content__card:hover {
      border-color: $tg-border-gray !important;
      cursor: default;
    }

    .schedule-block-date-group__button,
    .schedule-block-content__card-menu {
      display: none !important;
    }

    table {
      tr {
        &:hover {
          background-color: inherit;
        }
      }

      th:hover {
        background-color: inherit;
      }

      td,
      th {
        cursor: default;

        div {
          cursor: default;
        }
      }
    }

    .text-input {
      input,
      textarea {
        cursor: default;
        color: $tg-black;
      }
    }

    .multi-block-wrapper {
      .attachment-block-wrapper,
      .image-block-wrapper,
      .text-block-wrapper {
        border-color: transparent !important;
      }
    }

    .text-block-wrapper {
      textarea {
        color: $tg-black;
      }
    }

    .custom-table {
      .table-title {
        input {
          color: $tg-black;
        }
      }

      tbody {
        textarea {
          color: $tg-black;
        }
      }
    }

    .file-uploader {
      .attachment-block__upload-trigger,
      .image-block__upload-trigger {
        button {
          visibility: hidden;
        }
      }
    }

    .custom-table {
      fieldset {
        border-color: transparent !important;
      }
    }

    .object-table__document-column-menu,
    .object-table__document-row-menu {
      display: none;
    }

    .object-table--document {
      button {
        display: none;
      }

      .table-title__results {
        display: none;
      }
    }

    .object-table {
      td:last-child {
        display: none;
      }
    }

    .mui-data-table__row--hidden,
    .excel-paste-button,
    .filter-menu-react,
    .schedule-block-actions,
    .sort-menu-react,
    .user-menu-react {
      display: none;
    }

    .document-builder__block:hover {
      background: none;
      border-color: transparent;
    }

    &.document-builder__print-mode {
      .attachment-block {
        pointer-events: none;
      }

      .document-builder__block
        > :not(.enable-table-break):not(.schedule-block-wrapper),
      .schedule-block-content__card-wrapper {
        page-break-inside: avoid !important;
        page-break-after: auto !important;
      }

      .page-break-wrapper {
        display: block;
        padding: 0;
        page-break-before: always !important;

        > * {
          display: none;
        }
      }

      .object-table--document {
        margin: 8px 0;
      }

      .mui-data-table-wrapper {
        margin: 8px 0;
        border-radius: 0;

        > div {
          > div:first-child {
            background-color: transparent !important;
            min-height: 48px;
            padding: 0 8px;
          }
        }

        .table-title {
          input {
            font-size: 14px;
          }
        }

        table {
          table-layout: auto;

          thead {
            background-color: $tg-background-gray;

            th:nth-child(n + 2) {
              border-left: 1px solid $tg-border-gray;
            }
          }

          tr {
            height: 40px;
          }

          th,
          td {
            white-space: normal;
            font-size: 12px;

            > div {
              white-space: normal; // forces text wrapping in print mode so cells fit to page
            }

            .cell-input {
              min-width: auto !important;
              display: flex;
            }

            .col-input-expander {
              left: 0;
            }

            .col-input-expander,
            input,
            textarea {
              font-size: 12px;
            }
          }

          th .cell-input {
            padding: 0;
          }

          td .text-input--textarea {
            > div {
              padding: 4px;
            }
          }
        }
      }

      .document-header-cell {
        padding-left: 8px;
      }

      .text-block-wrapper {
        textarea {
          color: $tg-black;
        }
      }

      .custom-table {
        padding: 0;

        table {
          table-layout: auto;
        }

        thead {
          th {
            padding: 0 8px;
          }

          td:last-child {
            display: none;
            height: 37px !important;
          }

          input {
            padding: 0;
          }
        }

        tbody {
          textarea {
            color: $tg-black;
          }

          tr {
            &:last-child {
              height: 40px;
            }

            td:last-child {
              display: none;
            }
          }
        }
      }

      .object-table {
        padding: 0;
        tbody {
          tr:last-child {
            height: inherit;
          }
        }

        thead {
          tr {
            height: 40px;
          }
        }

        th {
          padding: 8px;
        }

        td {
          padding: 12px 8px;

          &:last-child {
            display: none;
          }
        }

        &.object-table--no-pagination {
          .mui-data-table-wrapper {
            > div {
              > div:nth-child(3) {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
              }
            }
            tbody {
              tr:last-child {
                height: 40px;
                td {
                  border-bottom: none;
                }
              }
            }
          }
        }
      }

      .schedule-block-wrapper {
        padding: 0;

        .schedule-block-content__date {
          margin: 0;
        }

        .schedule-block-content__card {
          border-radius: 0;
        }
      }
    }
  }
}

@media print {
  .document-builder.document-builder__print-mode {
    margin: 0;
    overflow: visible;
    position: relative;
  }
}
