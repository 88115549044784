@import "../../../styles/colors.module.scss";

.document-dialog__overlay {
  .dialog-content {
    border-top: 1px solid $tg-border-gray;
    border-bottom: 1px solid $tg-border-gray;
    width: 384px;
    padding: 16px 24px;
  }

  .dialog-actions {
    margin: 8px 16px;
  }

  .document-dialog__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
      font-size: 20px;
      font-weight: normal;
      margin: 0;
    }
  }

  .document-dialog__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .document-dialog__title {
      padding: 0;
    }
  }

  .autocomplete-input--filled {
    z-index: inherit;
  }

  .document-dialog__content-flex {
    display: flex;
    justify-content: space-between;
    > div {
      width: calc(50% - 4px);
    }
  }

  .document-dialog__content-helper-label {
    font-size: 12px;
    color: $tg-text-gray;
    margin: -4px 0 8px;
  }

  .text-input {
    height: 64px !important;
  }
}
