@import "../../../../styles/colors.module.scss";

.move-block-menu {
  position: absolute;
  background: $tg-white;
  border-radius: 4px;
  border: 2px solid $tg-border-gray;
  box-sizing: border-box;
  top: -56px;
  right: 8px;
  margin-left: 16px;
  display: flex;
  align-items: center;
  z-index: map-get($z-index, "doc-builder-tool");

  > div:nth-child(n + 2) {
    margin-left: 4px;
  }

  .move-block-menu__option {
    button {
      padding: 4px;
      transition: all 0.3s ease-in-out;
    }

    .icon {
      color: $tg-product-blue;
    }

    &.move-block-menu__option--delete {
      .icon-button {
        .icon {
          color: $tg-red;
        }
      }
    }

    &.move-block-menu__option--disabled {
      .icon-button {
        pointer-events: none;
        .icon {
          color: $tg-text-gray;
        }
      }
    }
  }
}
