@import "../../styles/colors.module.scss";

#collection-view-page {
  #collection-settings {
    .inner {
      padding: 16px;
      &.separator {
        border-top: 2px solid $tg-border-gray;
        margin-top: 8px;
      }
    }

    border: 2px solid $tg-border-gray;
    border-radius: 10px;

    .form-header {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: $tg-black;
      padding: 0 16px;
      height: 48px;
      background-color: $tg-product-background-blue;
      border-radius: 4px;
      margin-bottom: 16px;

      .description {
        display: inline-block;
        margin-left: 8px;
        color: $tg-text-gray;
      }
    }

    .read-only {
      height: 40px;
      padding: 0 8px;
      margin-bottom: 8px;
    }

    .form-footer {
      display: flex;
      margin-top: 24px;
    }

    .pro-tip {
      margin-bottom: 16px;
    }

    .editor-row {
      margin-bottom: 8px;
      display: flex;
      width: 100%;
      justify-content: flex-start;
      align-items: center;

      .arrow {
        margin: 0px 16px;
      }

      .linked-tab {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $tg-product-light-blue;
        border: 2px solid $tg-product-light-blue;
        border-radius: 4px;
        color: $tg-product-blue;
        padding: 8px 16px;
        white-space: nowrap;
        margin-right: 8px;
        font-size: 14px;
        height: 40px;
        box-sizing: border-box;

        svg.icon {
          margin-left: 8px;
        }
      }

      .inner {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        border: 2px solid $tg-border-gray;
        padding: 16px;
        border-radius: 4px;
        font-size: 14px;
        height: 40px;
        box-sizing: border-box;

        .label {
          .label-text {
            margin-right: 16px;
          }
          .helper-text {
            display: inline-block;
            color: $tg-text-gray;
          }
        }

        .menu {
          display: flex;
          justify-content: flex-end;

          .value {
            display: inline-block;
            margin-right: 16px;
          }
        }
      }
    }
  }
}

.collection-default-popper {
  .title {
    color: $tg-text-gray;
    font-size: 16px;
    margin-bottom: 4px;
  }
  .description {
    color: $tg-text-gray;
    font-size: 14px;
    margin-bottom: 16px;
  }

  .form {
    display: flex;
    justify-content: flex-start;
    font-size: 14px;
    margin-bottom: 24px;

    .input-label,
    .article {
      height: 40px;
      box-sizing: border-box;
      border-radius: 4px;
      margin-right: 8px;
      display: flex;
      align-items: center;
    }

    .input-label {
      border: 2px solid $tg-border-gray;
      padding: 8px;
      color: $tg-text-gray;
    }
    .article {
      background-color: $tg-background-gray;
      color: $tg-product-blue;
      padding: 0px 16px;
      div {
        text-align: center;
      }
    }
    .input {
      flex-grow: 1;
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-start;
  }
}

.booking-request-add-input {
  display: flex;
  align-items: center;
  border-radius: 100px;
  border: 2px solid $tg-border-gray;
  box-sizing: border-box;
  font-size: 14px;
  height: 40px;
  width: 280px;
  overflow: hidden;

  .icon {
    margin-left: 8px;
  }

  > div {
    width: 100%;
  }

  .tg-input__control {
    border: none;
    box-shadow: none;
    cursor: text;
    min-height: auto;
  }
}
