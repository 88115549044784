@import "../../../../styles/colors.module.scss";

#tml-entries {
  .sink-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    .keyword-search-input {
      &.open {
        width: 240px;
      }
    }

    & > div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

  .sink {
    padding-bottom: 32px;
  }
}

.sink,
.measure-portal {
  .grid-content.head {
    .grid-cell.traveler .inner div {
      text-align: right;
      width: 100%;
      box-sizing: border-box;
      margin-right: 24px;
    }
  }
  .tml-cell-popper-wrapper {
    display: block !important;
    height: 100%;
    width: 100%;
    border-radius: 4px;
    flex-grow: 2;
    box-sizing: border-box;
    padding: 8px;

    &:hover {
      background-color: $tg-product-background-blue;
    }

    .popper-menu {
      display: block;
      height: 100%;
    }
    .popper-anchor {
      display: flex;
      align-items: center;
      color: $tg-black;
      height: 100%;
    }
  }

  .highlight-fade {
    height: 100%;
    box-sizing: border-box;

    &.highlight {
      .tml-cell-popper-wrapper:hover {
        background-color: inherit;
      }
    }
  }

  .tml-cell {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 8px;

    &.entry-type {
      display: flex;
      align-items: center;
    }

    &.notes {
      display: flex;
      align-items: center;
    }

    &.tags {
      display: flex;
      align-items: center;
      .tag-chip {
        margin-right: 4px;
      }
    }

    .MuiInputBase-root {
      background-color: $tg-white;
    }
  }

  .entry-type-tag {
    padding: 4px 8px;
    font-size: 14px;
    background-color: $tg-light-green;
    border: 2px solid $tg-green;
    border-radius: 4px;

    &.outbound {
      background-color: $tg-light-red;
      border-color: $tg-red;
    }
  }
}
