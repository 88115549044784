@import "../../styles/colors.module.scss";

.registration-page {
  text-align: center;
  height: 100vh;

  a,
  .link {
    text-decoration: underline;
    color: $tg-product-blue;
    cursor: pointer;
  }
  h2,
  h6 {
    color: $tg-black;
  }

  h2 {
    font-size: 40px;
  }

  h4,
  p {
    color: $tg-text-gray;
  }

  .registration-page-header {
    display: flex;
    justify-content: space-between;
    padding: 0 24px;
    height: 80px;
    align-items: center;
    box-sizing: border-box;
  }

  .registration-page-header__branding {
    display: flex;
    align-items: flex-start;

    > img:first-child {
      margin-top: -2px;
      margin-right: 6px;
    }

    img {
      height: 32px;
    }
  }

  .registration-form {
    margin: auto;
    max-width: 480px;

    > :not(.loader):not(br):not(p) {
      margin-top: 20px;
      width: 100%;
    }

    > button {
      background-color: $tg-product-blue;
      border-radius: 4px;
      border: 0;
      color: $tg-white;
      cursor: pointer;
      display: inline-block;
      font-size: 16px;
      line-height: inherit;
      margin-bottom: 16px;
      margin-top: 0;
      max-width: 480px;
      min-height: 48px;
      padding: 0;
      text-decoration: none;
      width: 100%;
      transition: 0.3s ease-in-out;

      &.disabled {
        background: $tg-background-gray;
        color: $tg-text-gray;
        pointer-events: none;
      }
    }

    .checkbox-wrapper {
      display: flex;
      align-items: center;

      p {
        font-size: 14px;
        margin-left: 8px;
        text-align: left;

        > a {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    .form-input {
      hr {
        display: none;
      }

      input {
        -webkit-transition: all 200ms ease;
        background-color: $tg-white;
        border-radius: 4px;
        border: 1px solid $tg-product-blue;
        color: $tg-product-blue;
        display: block;
        font-size: 16px;
        height: 70px;
        line-height: 1.42;
        margin-bottom: 20px;
        padding: 8px 12px;
        padding-left: 20px;
        transition: all 200ms ease;
        vertical-align: middle;
        width: calc(100% - 34px);
      }

      input:focus {
        background-color: $tg-product-background-blue;
        border-color: $tg-product-background-blue;
        color: $tg-black;
        outline: 0;
      }

      input[disabled] {
        border-color: $tg-border-gray;
        color: $tg-text-gray;
        font-family: italic;
      }

      > div {
        border-bottom: none;
        margin: 0;
        width: 100%;
      }
    }
  }
}
