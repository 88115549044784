@import "../../styles/colors.module.scss";

.travel-planner-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  span {
    display: flex;
    align-items: center;
    &:nth-of-type(n + 2) {
      margin-left: 16px;
    }
  }

  .icon {
    color: $tg-black;

    &:hover {
      color: $tg-product-blue;
      &.red {
        color: $tg-red;
      }
    }
  }
}
