@import "../../styles/colors.module.scss";

.tile-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  margin-bottom: 8px;

  .tile-title__label {
    color: $tg-product-blue;
    font-size: 14px;
  }

  .tile-title__button-group {
    display: flex;
  }
}

.tile {
  background-color: $tg-white;
  border: 2px solid $tg-border-gray;
  border-radius: 12px;
  padding: 8px;
  font-size: 14px;

  .header {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $tg-product-dark-blue;
  }

  .description {
    color: $tg-text-gray;
    margin-bottom: 16px;
  }

  .email-copy-button {
    text-transform: none;
  }

  &.fullWidth {
    padding: 0px;
  }
}
