@import "../../styles/colors.module.scss";

#dashboard-page {
  .table-tools {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    .filters {
      display: flex;
      flex-grow: 1;

      .autocomplete-input {
        width: 184px;
      }
    }

    .button {
      margin-left: 8px;
    }

    .buttons {
      display: flex;
      align-items: center;
    }
  }

  .mui-data-table-wrapper {
    margin-top: 24px;
  }
}
