@import "../../styles/colors.module.scss";

.not-found-page {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  h1 {
    font-size: 120px;
    margin: 0;
  }

  img {
    display: inline-block;
  }

  .header {
    font-size: 24px;
    line-height: 30px;
    color: $tg-black;
    margin: 30px 0px 16px 0px;
  }

  .description {
    font-size: 14px;
    color: $tg-text-gray;

    a {
      color: $tg-product-blue;
      text-decoration: none;
    }
  }
}
