// file is named with .module to allow variable export
// https://github.com/facebook/create-react-app/issues/10047

$z-index: (
  "stacking-context": 1,
  "app": 100,
  "icon": 120,
  "doc-builder-tool": 140,
  "menu": 160,
  "select-control": 180,
  "sticky": 500,
  // mui default
    "modal": 1300,
  // compatability with react-dates calendar popper
    "react-dates": 1400
);

$tg-shadow: 0px 0px 32px rgba(0, 0, 0, 0.08);

$tg-black: #111111;
$tg-white: #ffffff;

$tg-background-gray: #f5f5f5;
$tg-border-gray: #e0e0e0;
$tg-text-gray: #9e9e9e;
$tg-light-gray: #eeeeee;
$tg-gray: #c4c4c4;
$tg-dark-gray: rgba(0, 0, 0, 0.54); // used to match mui-datatable's gray

$tg-product-background-blue: #f1f4f7;
$tg-product-light-blue: #e3edff;
$tg-product-blue: #2e71f1;
$tg-product-dark-blue: #161637;

$tg-tag-light-blue: #e3ebf2; // for user/airport tags

$tg-light-red: #f9e5e5;
$tg-red: #ed4444;

$tg-light-orange: #fbeae5;
$tg-orange: #ff7940;

$tg-light-yellow: #fdf0de;
$tg-yellow: #ffb249;

$tg-light-green: #e8f9eb;
$tg-green: #40b753;

$tg-light-blue: #e5f6fb;
$tg-blue: #41b7de;

$tg-light-purple: #ecefff;
$tg-purple: #6676ca;

:export {
  shadow: $tg-shadow;

  black: $tg-black;
  white: $tg-white;

  backgroundGray: $tg-background-gray;
  borderGray: $tg-border-gray;
  textGray: $tg-text-gray;
  lightGray: $tg-light-gray;
  gray: $tg-gray;
  darkGray: $tg-dark-gray;

  productBackgroundBlue: $tg-product-background-blue;
  productLightBlue: $tg-product-light-blue;
  productBlue: $tg-product-blue;
  productDarkBlue: $tg-product-dark-blue;

  tagLightBlue: $tg-tag-light-blue;

  lightRed: $tg-light-red;
  red: $tg-red;

  lightOrange: $tg-light-orange;
  orange: $tg-orange;

  lightYellow: $tg-light-yellow;
  yellow: $tg-yellow;

  lightGreen: $tg-light-green;
  green: $tg-green;

  lightBlue: $tg-light-blue;
  blue: $tg-blue;

  lightPurple: $tg-light-purple;
  purple: $tg-purple;
}
