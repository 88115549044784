@import "../../styles/colors.module";

.segment-chip-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .segment-chip {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .chip {
      border-radius: 4px;
      padding: 4px 8px;
      color: $tg-text-gray;
      font-size: 12px;
    }

    svg {
      display: block;
      margin: 0 4px;
    }
  }
}
