@import "../../styles/colors.module.scss";

.grid-card {
  width: 320px;
  border-radius: 16px;
  box-sizing: border-box;
  border: 2px solid $tg-border-gray;
  background-color: $tg-white;
  cursor: pointer;
  position: relative;

  a {
    text-decoration: none;
    color: $tg-black;
    display: block;
    padding: 16px;
  }

  .grid-card-utils {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: -8px -8px 20px -8px;

    > Button.button {
      .icon {
        height: 24px;
        width: 24px;
        min-width: 24px;
      }
    }
  }

  .grid-card-menu-trigger {
    .button {
      background-color: transparent;
    }

    &:hover .button {
      background-color: $tg-background-gray;
    }
  }

  &:hover,
  &.hover {
    border-color: $tg-product-blue;
  }

  .user-profile-icons {
    flex-grow: 1;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    .user-profile-icon {
      margin-right: 4px;
    }

    .more-users-count {
      background-color: $tg-light-gray;
      font-size: 10px;
      display: flex;
      width: 28px;
      height: 28px;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      span {
        display: inline-block;
      }
    }
  }

  .footer {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    .grid-tag-color-square {
      height: 16px;
      width: 16px;
      border-radius: 4px;
      background-color: $tg-black;
    }
  }

  .grid-card-title {
    font-size: 18px;
    margin-bottom: 40px;
  }
}
