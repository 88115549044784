@import "../../styles/colors.module.scss";

#grids-page {
  .title-tools {
    display: flex;
  }

  .grids-page-utils {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    .autocomplete-input {
      width: 200px;
    }

    .search {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .search-input {
        margin-right: 8px;
      }
    }
  }

  .empty-list-view {
    color: $tg-dark-gray;
  }

  .card-list-view {
    display: flex;
    align-items: flex-start;
    justify-self: flex-start;
    flex-wrap: wrap;
    padding-bottom: 56px;

    .grid-card {
      margin: 0px 24px 24px 0px;
    }
  }
}
