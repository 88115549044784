@import "../../styles/colors.module.scss";

.app-icon-frame {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 4px;
  transition: 0.3s ease-in-out;

  &.square {
    border-radius: 4px;
  }

  &.large {
    box-sizing: border-box;
    height: 40px;
    width: 40px;
    padding: 8px;
  }

  svg {
    padding: 0 !important;
  }

  &.product-blue {
    background-color: $tg-product-blue;
  }

  &.product-background-blue {
    background-color: $tg-product-background-blue;
  }

  &.tag-light-blue {
    background-color: $tg-tag-light-blue;
  }

  &.light-green {
    background-color: $tg-light-green;
  }

  &.background-gray {
    background-color: $tg-background-gray;
  }
}
