@import "../../../../styles/colors.module.scss";

.grid-tips-carousel {
  .title {
    color: $tg-product-blue;
    font-size: 10px;
    line-height: 13px;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    margin-bottom: 8px;
  }

  .carousel {
    ul.slider {
      padding-bottom: 36px;
    }

    .slide {
      background-color: $tg-white;

      .text {
        font-size: 18px;
        line-height: 23px;
        width: 321px;
        padding: 0;
        text-align: center;
        margin: 0 auto;
      }
    }

    .control-dots {
      li.dot {
        outline: none;
        box-shadow: none;
        background-color: $tg-text-gray;
      }
    }

    .control-arrow {
      background-color: $tg-white;
      color: $tg-product-blue;
      opacity: 1;
      cursor: default;

      .app-icon-frame {
        cursor: pointer;
      }

      &:hover {
        background: $tg-white;
      }

      &.control-prev {
        .app-icon-frame {
          float: left;
        }
      }
    }
  }
}
