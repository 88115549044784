@import "../../../../styles/colors.module.scss";

.add-block-menu {
  background: $tg-white;
  border-radius: 4px;
  border: 2px solid $tg-border-gray;
  box-sizing: border-box;
  z-index: map-get($z-index, "doc-builder-tool");

  .button {
    padding-left: 8px !important;
  }
}

.add-block-popper {
  background: $tg-white;
  border: 2px solid $tg-border-gray;
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: $tg-shadow;
  opacity: 0;
  margin-left: 16px;
  margin-top: -2px;
  padding: 24px 0;
  max-height: 400px;
  overflow-y: auto;
  transition: opacity 0.3s ease-in-out;
  visibility: hidden;
  z-index: map-get($z-index, "menu");

  &.add-block-popper--open {
    opacity: 1;
    visibility: visible;
  }

  div.add-block-popper__content {
    div.add-block-popper__block-group {
      h1 {
        font-size: 18px;
        color: $tg-text-gray;
        line-height: 1;
        margin: 0 16px 24px;
        font-weight: normal;
      }

      .add-block-popper__option {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 8px 16px;

        .add-block-popper__icon {
          border: 2px solid $tg-border-gray;
          border-radius: 8px;
          height: 40px;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $tg-black;
          margin-right: 16px;
          background: $tg-white;
        }

        .add-block-popper__text {
          h2 {
            font-size: 16px;
            color: $tg-black;
            margin: 0 0 4px;
            font-weight: normal;
          }
          p {
            font-size: 12px;
            color: $tg-text-gray;
            margin: 0;
          }
        }

        &:hover {
          background: $tg-background-gray;
        }
      }

      &:nth-of-type(n + 2) {
        margin-top: 16px;
      }
    }
  }
}
