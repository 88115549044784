@import "../../styles/colors.module.scss";

.object-table,
.custom-table {
  position: relative;
  z-index: map-get($z-index, "stacking-context");
  background: $tg-white;
  border-radius: 8px;

  #build-document-button-react {
    margin-left: auto;
  }

  .excel-paste-button {
    display: inline-block;
  }

  &.object-table--loading {
    min-height: 20vh;
    width: 100%;

    .object-table--loading-container {
      align-items: center;
      background: rgba(255, 255, 255, 0.9);
      bottom: 0;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: map-get($z-index, "stacking-context");

      > div {
        display: block;
      }
    }
  }

  &.custom-table--card,
  &.object-table--card {
    &.object-table--loading > .object-table--loading-container {
      border-radius: 8px;
      top: 24px;
    }

    .mui-data-table-wrapper {
      border: 2px solid $tg-border-gray;
      border-radius: 8px;
      margin-left: 0;
      margin-top: 16px;
      margin-bottom: 24px;

      > div {
        margin-left: 0;
        > div:first-child {
          padding: 8px 16px;
        }
      }
    }

    &.object-table--document {
      margin: 16px 0;

      .mui-data-table-wrapper {
        box-shadow: none;
        border-color: $tg-black;
      }

      tbody {
        td:nth-child(n + 2) {
          border-left: 1px solid #e0e0e0;
        }
      }

      // default document table colors
      &.data-type-activity {
        .mui-data-table-wrapper {
          border-color: $tg-red;

          > div {
            > div:first-child {
              background-color: $tg-light-red;
              border-bottom: 2px solid $tg-red;

              button {
                &.button {
                  background-color: $tg-red;
                  border-color: $tg-red;
                  border-width: 2px;
                }
                &:hover {
                  svg {
                    color: $tg-red;
                  }
                }
              }
            }
          }
        }
        .table-title {
          color: $tg-red;

          input {
            color: $tg-red;
          }
        }
      }
      &.data-type-air,
      &.data-type-traveler {
        .mui-data-table-wrapper {
          border-color: $tg-blue;

          > div {
            > div:first-child {
              background-color: $tg-light-blue;
              border-bottom: 2px solid $tg-blue;

              button {
                &.button {
                  background-color: $tg-blue;
                  border-color: $tg-blue;
                  border-width: 2px;
                }
                &:hover {
                  svg {
                    color: $tg-blue;
                  }
                }
              }
            }
          }
        }

        .table-title {
          color: $tg-blue;

          input {
            color: $tg-blue;
          }
        }
      }
      &.data-type-car {
        .mui-data-table-wrapper {
          border-color: $tg-purple;

          > div {
            > div:first-child {
              background-color: $tg-light-purple;
              border-bottom: 2px solid $tg-purple;

              button {
                &.button {
                  background-color: $tg-purple;
                  border-color: $tg-purple;
                  border-width: 2px;
                }
                &:hover {
                  svg {
                    color: $tg-purple;
                  }
                }
              }
            }
          }
        }

        .table-title {
          color: $tg-purple;

          input {
            color: $tg-purple;
          }
        }
      }
      &.data-type-hotel {
        .mui-data-table-wrapper {
          border-color: $tg-green;

          > div {
            > div:first-child {
              background-color: $tg-light-green;
              border-bottom: 2px solid $tg-green;

              button {
                &.button {
                  background-color: $tg-green;
                  border-color: $tg-green;
                  border-width: 2px;
                }
                &:hover {
                  svg {
                    color: $tg-green;
                  }
                }
              }
            }
          }
        }

        .table-title {
          color: $tg-green;

          input {
            color: $tg-green;
          }
        }
      }
      &.data-type-rail {
        .mui-data-table-wrapper {
          border-color: $tg-orange;
          > div {
            > div:first-child {
              background-color: $tg-light-orange;
              border-bottom: 2px solid $tg-orange;

              button {
                &.button {
                  background-color: $tg-orange;
                  border-color: $tg-orange;
                  border-width: 2px;
                }
                &:hover {
                  svg {
                    color: $tg-orange;
                  }
                }
              }
            }
          }
        }

        .table-title {
          color: $tg-orange;

          input {
            color: $tg-orange;
          }
        }
      }
      &.data-type-profile {
        .mui-data-table-wrapper {
          border-color: $tg-yellow;
          > div {
            > div:first-child {
              background-color: $tg-light-yellow;
              border-bottom: 2px solid $tg-yellow;

              button {
                &.button {
                  background-color: $tg-yellow;
                  border-color: $tg-yellow;
                  border-width: 2px;
                }
                &:hover {
                  svg {
                    color: $tg-yellow;
                  }
                }
              }
            }
          }
        }

        .table-title {
          color: $tg-yellow;

          input {
            color: $tg-yellow;
          }
        }
      }

      // color overrides that occur after default colors
      &.table-block-color--black {
        .mui-data-table-wrapper {
          border-color: $tg-black;

          > div {
            > div:first-child {
              background-color: $tg-white;
              border-bottom: 2px solid $tg-black;

              button {
                &.button {
                  background-color: $tg-black;
                  border-color: $tg-black;
                  border-width: 2px;
                }
                &:hover {
                  svg {
                    color: $tg-black;
                  }
                }
              }
            }
          }
        }

        .table-title {
          color: $tg-black;

          input {
            color: $tg-black;
          }
        }
      }

      &.table-block-color--gray {
        .mui-data-table-wrapper {
          border-color: $tg-gray;

          > div {
            > div:first-child {
              background-color: $tg-light-gray;
              border-bottom: 2px solid $tg-gray;

              button {
                &.button {
                  background-color: $tg-gray;
                  border-color: $tg-gray;
                  border-width: 2px;
                }
                &:hover {
                  svg {
                    color: $tg-gray;
                  }
                }
              }
            }
          }
        }

        .table-title {
          color: $tg-text-gray;

          input {
            color: $tg-text-gray;
          }
        }
      }

      &.table-block-color--blue {
        .mui-data-table-wrapper {
          border-color: $tg-blue;

          > div {
            > div:first-child {
              background-color: $tg-light-blue;
              border-bottom: 2px solid $tg-blue;

              button {
                &.button {
                  background-color: $tg-blue;
                  border-color: $tg-blue;
                  border-width: 2px;
                }
                &:hover {
                  svg {
                    color: $tg-blue;
                  }
                }
              }
            }
          }
        }

        .table-title {
          color: $tg-blue;

          input {
            color: $tg-blue;
          }
        }
      }

      &.table-block-color--green {
        .mui-data-table-wrapper {
          border-color: $tg-green;

          > div {
            > div:first-child {
              background-color: $tg-light-green;
              border-bottom: 2px solid $tg-green;

              button {
                &.button {
                  background-color: $tg-green;
                  border-color: $tg-green;
                  border-width: 2px;
                }
                &:hover {
                  svg {
                    color: $tg-green;
                  }
                }
              }
            }
          }
        }

        .table-title {
          color: $tg-green;

          input {
            color: $tg-green;
          }
        }
      }

      &.table-block-color--orange {
        .mui-data-table-wrapper {
          border-color: $tg-orange;

          > div {
            > div:first-child {
              background-color: $tg-light-orange;
              border-bottom: 2px solid $tg-orange;

              button {
                &.button {
                  background-color: $tg-orange;
                  border-color: $tg-orange;
                  border-width: 2px;
                }
                &:hover {
                  svg {
                    color: $tg-orange;
                  }
                }
              }
            }
          }
        }
        .table-title {
          color: $tg-orange;

          input {
            color: $tg-orange;
          }
        }
      }

      &.table-block-color--purple {
        .mui-data-table-wrapper {
          border-color: $tg-purple;

          > div {
            > div:first-child {
              background-color: $tg-light-purple;
              border-bottom: 2px solid $tg-purple;

              button {
                &.button {
                  background-color: $tg-purple;
                  border-color: $tg-purple;
                  border-width: 2px;
                }
                &:hover {
                  svg {
                    color: $tg-purple;
                  }
                }
              }
            }
          }
        }

        .table-title {
          color: $tg-purple;

          input {
            color: $tg-purple;
          }
        }
      }

      &.table-block-color--red {
        .mui-data-table-wrapper {
          border-color: $tg-red;

          > div {
            > div:first-child {
              background-color: $tg-light-red;
              border-bottom: 2px solid $tg-red;

              button {
                &.button {
                  background-color: $tg-red;
                  border-color: $tg-red;
                  border-width: 2px;
                }
                &:hover {
                  svg {
                    color: $tg-red;
                  }
                }
              }
            }
          }
        }

        .table-title {
          color: $tg-red;

          input {
            color: $tg-red;
          }
        }
      }

      &.table-block-color--yellow {
        .mui-data-table-wrapper {
          border-color: $tg-yellow;

          > div {
            > div:first-child {
              background-color: $tg-light-yellow;
              border-bottom: 2px solid $tg-yellow;

              button {
                &.button {
                  background-color: $tg-yellow;
                  border-color: $tg-yellow;
                  border-width: 2px;
                }
                &:hover {
                  svg {
                    color: $tg-yellow;
                  }
                }
              }
            }
          }
        }

        .table-title {
          color: $tg-yellow;

          input {
            color: $tg-yellow;
          }
        }
      }
    }

    &.object-table--no-pagination {
      .mui-data-table-wrapper {
        > div {
          > div:nth-child(3) {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
          }
        }
        tbody {
          tr:last-child {
            td {
              border-bottom: none;
            }
          }
        }
      }
    }
  }

  &.object-table--report {
    tr:hover {
      background: inherit;
    }

    .mui-data-table-wrapper {
      > div {
        border-radius: 0;
      }
    }

    > div {
      > div {
        > div:nth-child(3) {
          border-left: 1px solid $tg-border-gray;
          border-right: 1px solid $tg-border-gray;
        }
      }
    }

    table:first-child {
      border-top: 3px solid $tg-border-gray;
    }

    &.object-table--report-blue {
      table:first-child {
        border-top: 3px solid $tg-product-blue;
      }
    }

    &.object-table--report-green {
      table:first-child {
        border-top: 3px solid $tg-green;
      }
    }

    th,
    td {
      border-right: 1px solid $tg-border-gray;
      &:last-child {
        border-right: none;
      }
    }

    th {
      background-color: $tg-background-gray;
    }

    tr {
      &:nth-child(even) {
        background-color: $tg-background-gray;
      }
    }
  }

  &.object-table--unclickable {
    td {
      cursor: default;

      > div {
        cursor: default;
      }
    }
  }

  &.object-table--warning {
    h6 {
      color: $tg-red;
    }

    .mui-data-table-wrapper {
      background-color: #ffefed;
      border: 1px solid $tg-red;
      border-radius: 8px;
      margin: 0 0 24px;
      padding-right: 16px;
      padding-bottom: 24px;

      tr,
      tr:hover {
        background-color: inherit;
      }

      td {
        border-bottom: none;
      }
    }
  }

  .table-title {
    display: flex;
    align-items: center;

    .table-title__name {
      position: relative;
      font-size: 16px;
      display: flex;
      align-items: center;
    }

    .table-title__results {
      font-size: 14px;
      margin-left: 8px;
      white-space: nowrap;
    }

    .text-input {
      margin: 0;
      fieldset {
        border: none;
      }

      input {
        font-size: 20px;
        padding: 0;
      }
    }

    .table-title__edit-icon {
      visibility: hidden;
      opacity: 0;
      transition: 0.3s ease-in-out;
      margin-left: 8px;
      font-size: 20px;
      color: $tg-text-gray;
    }

    &:hover {
      .table-title__edit-icon {
        visibility: visible;
        opacity: 1;
      }
    }

    &:focus-within {
      .table-title__edit-icon {
        visibility: hidden;
        opacity: 0;
      }
    }
  }

  .mui-data-table-wrapper {
    position: relative;
    margin-left: -14px;

    .table-header--custom {
      border-bottom: 1px solid $tg-border-gray;
    }

    .table-header__icon {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin: 0 12px 0 4px;
      background-position: center;
      background-size: contain;
    }

    .price-col-label-wrapper {
      white-space: nowrap;

      .price-col {
        display: inline-flex;
      }
    }

    .price-col {
      color: $tg-green;
      width: 100%;
      display: block;
      text-align: right;
    }

    .mui-data-table__row--cancelled {
      background-color: lighten($tg-light-red, 5);
      .price-col {
        color: $tg-red;
      }
    }

    .mui-data-table__row--disabled {
      opacity: 0.5;
    }

    .mui-data-table__row--hidden {
      td {
        color: rgba(0, 0, 0, 0.2);

        .price-col {
          color: rgba(0, 0, 0, 0.2);
        }

        .tag-chip {
          opacity: 0.5;
        }
      }
    }

    .table-data__taglike-wrapper {
      max-width: 176px;
      display: inline-flex;
      align-items: center;

      .table-data__taglike-text {
        color: $tg-product-dark-blue;
        font-size: 14px;
        height: 22px;
        line-height: 22px;
        overflow-x: hidden;
        padding: 0 8px;
        text-overflow: ellipsis;
      }
    }

    .quick-add-button {
      position: absolute;
      bottom: 8px;
      left: 0px;
      z-index: map-get($z-index, "icon");
    }

    .custom-toolbar-select {
      display: flex;
      align-items: center;

      .grid-add-menu {
        margin-right: 12px;
      }

      .icon-button {
        display: flex;
        align-items: center;
        height: 40px;
        width: 40px;

        &:hover {
          svg {
            color: $tg-product-blue;
          }
        }
      }

      .icon {
        color: $tg-black;
      }
    }

    .table-data__button {
      transition: all 0.2s ease-in-out;
      color: $tg-product-blue;
      background: transparent;
      border: none;
      box-shadow: none;
      text-transform: none;
      text-align: center;
      border-radius: 400px;
      padding: 4px 0;

      span {
        padding: 0 8px;
      }

      &.table-data__button--itin {
        width: 54px;
      }
      &.table-data__button--search {
        max-width: 124px;
        margin-left: 26px;
      }

      &:hover {
        background-color: $tg-product-blue;
        color: $tg-white;
        cursor: pointer;
      }
    }

    .table-data__users {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;

      .table-data__user-tag {
        border-radius: 4px;
        background: $tg-tag-light-blue;
        color: $tg-product-dark-blue;
        height: 24px;
        padding: 0 8px;
        display: flex;
        align-items: center;

        &:nth-child(n + 2) {
          margin-left: 8px;
        }
      }
    }

    .table-data__users-document {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }

    .text--warning {
      color: $tg-red;
      border-bottom: 1px solid $tg-red;
    }
  }

  .tag-chip {
    max-width: 180px;
    > span {
      text-overflow: ellipsis;
      overflow-x: hidden;
    }
  }

  div.tag-chip:nth-child(n + 2) {
    margin-left: 8px;
  }

  .user-tag__counter {
    margin-left: 8px;
    color: $tg-black;
    background: $tg-tag-light-blue;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: normal;
    height: 24px;
    width: 24px;
    border-radius: 4px;
  }
}

.object-table__tooltip--warning {
  display: inline-flex;
  align-items: center;
  color: $tg-red;
}

.object-table__tooltip--icon {
  font-size: 16px;
  line-height: auto;
  align-self: center;
  margin-left: 2px;
}

th.document-header-cell {
  position: relative;
  padding: 4px 16px;
  &:hover {
    background-color: $tg-background-gray;
  }

  overflow: visible;

  &:first-child {
    .object-table__column-move-button--left {
      pointer-events: none;
      .icon {
        color: $tg-text-gray !important;
      }
    }
  }

  &:last-of-type {
    .object-table__column-move-button--right {
      pointer-events: none;
      .icon {
        color: $tg-text-gray !important;
      }
    }
  }

  input {
    color: $tg-black;
  }

  .document-header-cell-label {
    white-space: nowrap;
  }
}

.mui-data-table__row--with-document-row-menu {
  td:last-child {
    overflow: visible !important;
    position: sticky;
    border-left: none !important;
    right: 0;
    width: 0;
    padding: 0;
  }
}

.custom-table {
  th.document-header-cell {
    position: relative;
    padding: 4px;
  }

  tbody {
    tr {
      td {
        padding: 0 4px;
      }
    }
  }

  td:last-child {
    min-width: 112px;
  }
}

.object-table,
.custom-table {
  tbody {
    tr:first-child {
      .object-table__document-row-menu-button--move-up {
        cursor: default;
        pointer-events: none;
        cursor: default;
        .icon {
          color: $tg-text-gray !important;
        }
      }
    }

    tr:last-child {
      .object-table__document-row-menu-button--move-down {
        cursor: default;
        pointer-events: none;
        .icon {
          color: $tg-text-gray !important;
        }
      }
    }
  }
}

.object-table__document-row-menu {
  visibility: hidden;
  opacity: 0;
  top: -22px;
  right: 8px;
  position: absolute;
  border-radius: 4px;
  border: 2px solid $tg-border-gray;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background: $tg-white;
  transition: 0.3s ease-in-out;

  button {
    box-sizing: border-box;
    padding: 4px;

    &:nth-child(n + 2) {
      margin-left: 4px;
    }

    &.object-table__document-row-menu-button--disabled {
      pointer-events: none;
      .icon {
        color: $tg-text-gray !important;
      }
    }
  }
}

th.document-header-cell:hover {
  .object-table__document-column-menu {
    visibility: visible;
    opacity: 1;
  }
}

.object-table__document-column-menu {
  visibility: hidden;
  opacity: 0;
  bottom: -44px;
  position: absolute;
  border-radius: 4px;
  border: 2px solid $tg-border-gray;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background: $tg-white;
  transition: 0.3s ease-in-out;
  z-index: map-get($z-index, "doc-builder-tool");

  button {
    box-sizing: border-box;
    padding: 4px;

    &:nth-child(n + 2) {
      margin-left: 4px;
    }

    &.object-table__document-column-menu-button--disabled {
      pointer-events: none;
      button {
        .icon {
          color: $tg-text-gray !important;
        }
      }
    }
  }
}
