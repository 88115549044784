@import "../../styles/colors.module.scss";

.parsed-email {
  overflow: hidden;
  height: auto;
  background-color: #e6f1ff;
  border-radius: 4px;
  margin-bottom: 8px;
  padding-right: 0;
  position: relative;
  transition: padding-right 0.2s ease-in-out;

  &:last-of-type {
    margin-bottom: 0;
  }

  .email {
    display: flex;
    align-items: center;
    font-size: 14px;
    height: 28px;
    padding: 6px 12px;
    color: $tg-product-blue;

    .icon {
      margin-right: 16px;
    }

    .email__status-label {
      display: flex;
      align-items: center;
      min-width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 20px;

      .tooltip {
        line-height: 100%;
      }

      h4 {
        margin: 0px 8px 0px 0px;
        font-weight: normal;
        font-size: 14px;
        white-space: normal;
      }

      .icon {
        margin-right: 0px;
      }
    }

    .email__subject-label {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-weight: normal;
      font-size: 14px;
    }

    > :last-child:not(h4) {
      align-self: center;
      flex-grow: 1;
      text-align: right;
    }
  }

  .buttons {
    button {
      text-transform: none;

      &:hover {
        background: transparent;
      }
    }

    .parsing-action-button {
      border: none;
      color: $tg-product-blue;
      cursor: pointer;
      display: inline-block;
      font-size: 14px;
      margin: 0 0 0 50px;
      transition: all 0.2s ease-in-out;
      white-space: nowrap;

      &:hover {
        position: relative;
        padding-bottom: 6px;
      }
    }
  }

  &.canceled {
    background-color: $tg-background-gray;
    .parsing-action-button,
    .email,
    .clear-button {
      color: $tg-text-gray;
    }
  }

  &.completed {
    background-color: $tg-light-green;
    .parsing-action-button,
    .email,
    .clear-button {
      color: $tg-green;
    }
  }

  &.in-review {
    background-color: $tg-light-orange;
    .parsing-action-button,
    .email,
    .clear-button {
      color: $tg-orange;
    }
  }

  &.failed {
    background-color: $tg-light-red;
    .parsing-action-button,
    .email,
    .clear-button {
      color: $tg-red;
    }
  }

  .clear-button {
    bottom: 0;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    overflow: hidden;
    position: absolute;
    right: -32px;
    top: 0;
    transition: all 0.2s ease-in-out;
    width: 0;
    padding-right: 32px;
    font-size: 14px;
  }

  &.finished:hover {
    padding-right: 5em;

    .clear-button {
      width: 72px;
    }
  }
}
