@import "../../../../styles/colors.module.scss";

.grid-add-menu {
  display: flex;
  align-items: center;
  .icon {
    cursor: pointer;
  }
}

.grid-add-popper {
  background: $tg-white;
  border-radius: 4px;
  border: 2px solid $tg-border-gray;
  box-shadow: $tg-shadow;
  margin-bottom: 4px;
  padding: 4px 0px;
  border: 1px solid $tg-border-gray;
  z-index: map-get($z-index, "menu");

  .popper-row {
    font-size: 13px;
    padding: 8px 12px;
    cursor: pointer;

    &:hover {
      background-color: #deebff;
    }
  }
}
