@import "../tile.scss";

.email-parsing-tile {
  padding: 0 8px;

  button.email-copy-button {
    font-size: 12px;
    line-height: 1.25;
    padding: 8px;
    color: $tg-orange;
    background-color: $tg-light-orange;
    border: 2px solid $tg-light-orange;
    word-break: break-all;
    text-align: left;
    margin-bottom: 8px;

    &:hover {
      border-color: $tg-orange;
      background-color: $tg-light-orange;
      cursor: pointer;
    }
  }

  .email-parsing-tile__email-group {
    margin-bottom: 8px;
  }

  .app-tooltip-wrapper {
    overflow: hidden;
  }

  .parsed-email {
    cursor: pointer;
    margin-bottom: 8px;

    .email {
      padding: 6px 8px;

      .icon {
        margin-right: 8px;
      }
    }

    .email__status-label {
      min-width: 64px;
      margin-right: 16px;
    }

    .app-tooltip-wrapper {
      pointer-events: none; // prevents tooltip in small tile
    }

    .buttons {
      display: none;
    }

    .clear-button {
      display: none;
    }
  }
}
