@import "../../styles/colors.module.scss";

#collections-page {
  .new-container {
    position: relative;
    background-color: $tg-product-background-blue;

    box-sizing: border-box;
    padding: 24px;
    height: 264px;
    border-radius: 10px;

    .title {
      font-size: 16px;
      color: $tg-product-blue;
    }

    .description {
      margin-top: 24px;
      font-size: 14px;
      color: $tg-text-gray;
    }

    .form {
      margin-top: 16px;
      display: flex;

      button.button {
        height: 48px;
      }

      .input-controller {
        .text-input {
          width: 248px;
          margin-right: 8px;

          .MuiInputBase-root {
            height: 48px;
            background-color: $tg-white;
          }
        }

        .input-controller-helper {
          margin-top: 8px;
        }
      }
    }

    img.woman-writing {
      position: absolute;
      top: -53px;
      right: 0;
      z-index: map-get($z-index, "icon");
    }
  }

  .list-view {
    margin-top: 24px;
    .title {
      font-size: 16px;
      margin-top: 24px;
    }
  }

  #collection-stack {
    margin-top: 16px;
  }

  .stack {
    td.MuiTableCell-root {
      padding: 20px;
    }

    .tag-chip {
      margin-right: 8px;
    }
    .tools {
      display: flex;
      justify-content: flex-end;
    }
  }

  #collections-page-search-input {
    width: 344px;
    input.search-input__field {
      width: 304px;
    }
  }
}

.tg-popper {
  .popper-row {
    display: flex;
    align-items: center;
    font-size: 14px;
    height: 32px;
    padding: 0 12px 0 8px;
    border-radius: 4px;
    cursor: pointer;

    .icon {
      margin-right: 8px;
    }

    &:hover {
      background-color: $tg-background-gray;
    }
  }
}
