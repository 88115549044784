@import "../../../styles/colors.module";

.merge-profiles-dialog {
  min-width: 504px;

  .dialog-content {
    overflow-y: auto;
    padding: 24px 24px 0;
  }

  .dialog-title {
    padding: 16px 24px;
    border-bottom: 1px solid $tg-border-gray;
  }

  .merge-profiles-dialog__subtitle {
    text-align: center;
    margin-bottom: 24px;
    font-size: 16px;
  }

  .merge-warning {
    text-align: center;
    font-size: 14px;
    color: $tg-text-gray;
    margin: 16px 0px 32px 0px;
    max-width: 400px;
  }

  .profile-buttons {
    .button {
      margin-bottom: 8px;
    }
  }

  .dialog-actions {
    margin: 8px 16px;
  }
}
