@import "../../../../../styles/colors.module.scss";

.tml-cell.segment {
  .label {
    width: 100%;

    .title {
      font-size: 14px;
      color: $tg-product-dark-blue;
      margin-bottom: 5px;
      width: 100%;
    }

    .subtitle {
      font-size: 12px;
      color: $tg-text-gray;
      width: 100%;
    }
  }
}

.tml-segment-menu {
  width: 360px;

  .header {
    color: $tg-text-gray;
    font-size: 10px;
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  .trip-list {
    margin-bottom: 24px;
  }

  .tml-segment-trip-row {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 16px;
    cursor: pointer;

    svg.icon {
      margin-right: 8px;
    }

    .title {
      font-size: 14px;
      margin-bottom: 4px;
    }

    .subtitle {
      font-size: 12px;
      color: $tg-text-gray;
    }
  }

  .override-inputs {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;

    .text-input {
      flex-grow: 1;
    }

    .date-input-flex {
      margin-left: 8px;
      .MuiInputBase-root {
        width: 130px;
      }
      input.MuiInputBase-input {
        padding: 16px 8px;
        width: auto;
      }
      form {
        .MuiInputBase-root {
          width: 112px;
        }
        input[type="time"] {
          width: 100px;
        }
        fieldset {
          width: 92px;
        }
      }
    }
  }
}
