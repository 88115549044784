@import "../../../../styles/colors.module.scss";

.attachment-block {
  min-height: 24px;

  .app-tooltip-wrapper {
    display: block;
  }

  .attachment-block__upload-trigger {
    cursor: pointer;
    display: flex;
    align-items: center;

    img {
      width: 100%;
      border-radius: 4px;
    }

    button {
      width: 100%;
      height: 80px;
      border-radius: 4px;
    }

    > span {
      margin: auto;
      width: 100%;
    }
  }

  a {
    text-decoration: none;
  }

  .attachment-block__download-link {
    display: block;
    margin-left: auto;
    display: flex;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 4px;

    > span {
      display: flex;
      flex-direction: column;
    }

    .icon {
      height: 32px;
      width: 32px;
      color: $tg-product-blue;
    }

    .attachment-block__download-name {
      color: $tg-product-blue;
      margin-top: 8px;
    }
  }
}
