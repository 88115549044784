@import "../../styles/colors.module";

.tab-header {
  border: 2px solid $tg-border-gray;
  border-radius: 4px;
  padding: 4px;
  display: flex;
  justify-content: space-between;

  .tab-header--tab {
    font-size: 14px;
    text-align: center;
    border-radius: 4px;
    padding: 8px;
    flex-grow: 1;
    cursor: pointer;
    transition: background-color ease-out 200ms;

    &.active {
      font-family: "Circular Bold" !important;
      background-color: $tg-product-light-blue;
      color: $tg-product-blue;
    }
  }
}
