@import url("https://fonts.googleapis.com/icon?family=Material+Icons+Round");
@import "./colors.module.scss";

@font-face {
  font-family: "Circular Bold";
  src: url("./circular-bold.otf") format("opentype");
}
@font-face {
  font-family: "Circular Book";
  src: url("./circular-book.otf") format("opentype");
}
@font-face {
  font-family: "Circular Medium";
  src: url("./circular-medium.otf") format("opentype");
}
@font-face {
  font-family: "Roboto Mono";
  src: url("./roboto-mono.ttf") format("truetype");
}

* {
  font-family: "Circular Book" !important;
  -webkit-font-smoothing: antialiased;

  &.circular-bold {
    font-family: "Circular Bold" !important;
    font-weight: normal;
    letter-spacing: 0.02rem;
  }

  &.circular-medium {
    font-family: "Circular Medium" !important;
  }

  &.roboto-mono {
    font-family: "Roboto Mono" !important;
  }
}

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $tg-white;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;

  i,
  br,
  .noselect {
    user-select: none;
  }
}

.sb-show-main {
  width: auto; // prevents horizontal overflow in StoryBook preview pane

  .app-sidebar {
    height: calc(100vh - 32px);
  }

  .company-logo {
    height: 40px;
    width: 40px;
  }
}

body {
  overflow-y: hidden;
}

.impersonation-indicator-wrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: map-get($z-index, "modal");

  button.button {
    border-radius: 0 !important;
  }
}

.app-loading {
  background-color: $tg-background-gray;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  text-align: center;

  .howie-loader-frame {
    width: 200px;
  }
}

#root {
  height: 100%;
}

#app {
  display: flex;
  position: relative;
  height: 100%;
  max-width: 100vw;
  justify-content: flex-start;
  align-items: flex-start;

  &.impersonate-active {
    .app-sidebar {
      box-sizing: border-box;
      padding-bottom: 56px;
    }

    #app-page {
      box-sizing: border-box;
      margin-bottom: 56px;
    }

    .grid-view-sidebar .inner {
      margin-bottom: 56px;
    }

    .page-container {
      padding-bottom: 56px;
    }
  }
}

#app-page {
  margin: 0 auto;
  padding: 0;
  flex: auto;
  max-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;

  .settings-nav {
    display: none;
  }

  .page-container {
    padding: 16px 32px 0 0;
    min-width: 760px;
  }
}

.page-with-sidebar-container {
  display: flex;
  margin-left: -32px;
  height: 100%;
  max-height: 100vh;
  min-width: 960px;
  overflow: hidden;
}

.mdc-typography--subheading1 {
  margin-top: 24px;
}

input:-webkit-autofill {
  background-color: none !important;
}

@media print {
  #app-page {
    max-height: auto;
    overflow-x: visible; // required to allow proper pdf exporting in doc builder
    overflow-y: visible; // required to allow proper pdf exporting in doc builder
    padding: 0;
  }
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
