@import "../../../../../styles/colors.module.scss";

.tag-group-editor-popper {
  background: $tg-white;
  border: 2px solid $tg-border-gray;
  border-radius: 8px;
  box-shadow: $tg-shadow;
  margin-top: 8px;
  max-height: calc(100vh - 96px);
  overflow: auto;

  .tag-group-editor-popper__content {
    width: 240px;
    padding: 16px 24px;

    .tag-group-editor-popper__row {
      margin-bottom: 16px;
    }

    .tag-group-editor-popper__input-flex {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      > div:nth-child(2n) {
        margin-left: 8px;
      }
      .outlined-input {
        width: 200px;
        > div {
          > div {
            font-size: 14px;
            padding: 8px 16px 8px 16px;
            outline: none !important;
          }
        }

        fieldset {
          border: 1px solid $tg-border-gray;
        }

        svg {
          color: $tg-black;
          margin-right: 2px;
        }
      }

      .autocomplete-input {
        width: 200px;
        font-size: 14px;

        > div {
          border-color: $tg-border-gray !important;
        }
      }

      .tag-group-editor-popper__input--disabled {
        box-sizing: border-box;
        border-radius: 4px;
        border: 1px solid $tg-border-gray;
        background: $tg-background-gray;
        height: 40px;
        margin-left: 16px;
        width: 200px;
      }
    }

    .tag-group-editor-popper__checkbox-flex {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: $tg-text-gray;
    }

    .tag-group-editor-popper__buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;

      button.button {
        &:nth-of-type(n + 2) {
          margin-left: 16px;
        }
      }
    }
  }
}

.tag-group-editor__tag-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  font-size: 14px;
  margin: 12px 0px;
  color: $tg-black;

  .icon.drag-handle {
    cursor: move;
    margin-right: 4px;
  }

  .name-input {
    background-color: $tg-white;
    border-radius: 4px;
    margin-left: 8px;
    > div {
      margin-bottom: 0;
    }
  }

  .button {
    margin-left: 8px;
  }

  .color-palette-popper__anchor {
    margin-right: 4px;
  }
}

.outlined-input {
  height: 40px;

  > div {
    height: 100%;
  }
}

.outlined-input__option--color {
  padding: 4px 8px;
  border-radius: 2px;
  font-size: 11px;
  text-transform: uppercase;
  line-height: 1;
}
