@import "../../../../styles/colors.module.scss";

.format-block-menu {
  position: absolute;
  background: $tg-white;
  border-radius: 4px;
  border: 2px solid $tg-border-gray;
  top: -56px;
  left: -2px;
  right: 0;
  box-sizing: border-box;
  width: fit-content;
  margin: auto;
  display: flex;
  align-items: center;
  z-index: map-get($z-index, "doc-builder-tool");

  &.format-block-menu--first {
    right: auto;
  }

  &.format-block-menu--last {
    left: auto;
    right: -2px;
  }

  button.button:nth-child(n + 2) {
    margin-left: 4px;
  }

  > div:nth-child(n + 2) {
    margin-left: 4px;
  }

  .format-block-menu__option {
    button {
      padding: 4px;
    }

    .icon {
      color: $tg-text-gray;
      font-size: 24px;
    }

    .text-input {
      margin: 0;

      fieldset {
        border-color: transparent !important;
        border-radius: 4px;
        border-width: 2px !important;
      }

      input {
        width: 40px;
        padding: 8px 0 8px 16px;
      }
    }
  }
}
