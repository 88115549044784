@import "../../styles/colors.module.scss";

.color-palette {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
  flex-wrap: wrap;
}

.color-palette,
.color-palette-popper__anchor {
  .palette {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    color: $tg-white;
    border: 2px solid $tg-white;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    margin: 0px 8px 8px 0px;

    &.full-text {
      width: 48px;
    }

    span {
      display: inline-block;
      vertical-align: top;
      line-height: 100%;
    }

    svg {
      display: inline-block;
      vertical-align: top;
    }

    &.green {
      background-color: $tg-green;
      border-color: $tg-green;
    }
    &.yellow {
      background-color: $tg-yellow;
      border-color: $tg-yellow;
    }
    &.orange {
      background-color: $tg-orange;
      border-color: $tg-orange;
    }
    &.red {
      background-color: $tg-red;
      border-color: $tg-red;
    }
    &.blue {
      background-color: $tg-blue;
      border-color: $tg-blue;
    }
    &.purple {
      background-color: $tg-purple;
      border-color: $tg-purple;
    }

    &.light {
      color: $tg-black;

      &.green {
        background-color: $tg-light-green;
      }
      &.yellow {
        background-color: $tg-light-yellow;
      }
      &.orange {
        background-color: $tg-light-orange;
      }
      &.red {
        background-color: $tg-light-red;
      }
      &.blue {
        background-color: $tg-light-blue;
      }
      &.purple {
        background-color: $tg-light-purple;
      }
    }
  }
}
