@import "../../styles/colors.module";

.stack {
  font-size: 14px;

  .empty-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 16px;
    color: $tg-text-gray;
    text-align: center;
    margin-top: 72px;

    img {
      width: 120px;
      margin-bottom: 8px;
    }
  }

  .empty {
    tbody.MuiTableBody-root {
      display: none;
    }
  }

  thead.MuiTableHead-root {
    tr.MuiTableRow-root {
      height: auto;
    }
  }

  &.hide-table-head {
    table.MuiTable-root thead.MuiTableHead-root {
      display: none !important;
    }
  }
  &.hide-toolbar {
    .MuiPaper-root > .MuiToolbar-root {
      display: none !important;
    }
  }

  .MuiPaper-root {
    margin: 0;
  }

  .MuiToolbar-root {
    min-height: auto !important;

    & > div {
      padding: 0;
    }

    .stack-title {
      display: flex;
      justify-content: flex-start;
    }
  }

  table.MuiTable-root {
    border-spacing: 0px 8px;
    tr.MuiTableRow-root {
      &.MuiTableRow-hover:hover {
        background: inherit;
      }

      &.MuiTableRow-footer {
        td.MuiTableCell-footer {
          border: none !important;
          padding: 0;
        }
      }

      &.empty {
        td.MuiTableCell-root {
          border: none !important;
        }
      }

      td.MuiTableCell-root {
        border-top: 2px solid $tg-border-gray;
        border-bottom: 2px solid $tg-border-gray;
        border-left: none;
        border-right: none;
        padding: 8px;

        &:first-child {
          border-radius: 12px 0px 0px 12px;
          border-left: 2px solid $tg-border-gray;
        }

        &:last-child {
          border-radius: 0px 12px 12px 0px;
          border-right: 2px solid $tg-border-gray;
        }
      }

      th.MuiTableCell-root {
        border: none;
        background-color: $tg-background-gray;
        color: $tg-text-gray;
        padding: 8px;

        span.MuiButton-label {
          color: $tg-text-gray;
        }

        &:first-child {
          border-radius: 4px 0px 0px 4px;
        }

        &:last-child {
          border-radius: 0px 4px 4px 0px;
        }
      }
    }
  }
}

.stack-toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  & > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .add-button {
      cursor: pointer;

      .app-icon-frame {
        margin-left: 8px;
        padding: 8px;
      }
    }
  }

  .keyword-search-input {
    &.open {
      width: 240px;
    }
  }

  & > div:last-child {
    justify-content: flex-end;
    & > div {
      margin-left: 8px;
      .app-icon-frame.square {
        padding: 8px;
      }
    }
  }
}

.stack-add-input {
  display: flex;
  align-items: center;
  border-radius: 100px;
  border: 2px solid $tg-border-gray;
  box-sizing: border-box;
  font-size: 14px;
  height: 40px;
  width: 280px;
  overflow: hidden;

  .icon {
    margin-left: 8px;
  }

  > div {
    width: 100%;
  }

  .tg-input__control {
    border: none;
    box-shadow: none;
    cursor: text;
    min-height: auto;
  }
}
