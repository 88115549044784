@import "../../styles/colors.module.scss";

.tag-chip {
  display: inline-flex;
  font-size: 14px;
  padding: 0 8px;
  height: 24px;
  align-items: center;
  box-sizing: border-box;
  border-style: solid;
  border-width: 2px;
  border-radius: 4px;
  overflow: hidden;
  max-width: 100%;
  box-sizing: border-box;

  span {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .icon-flex-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .icon {
    margin-right: 4px;
  }

  &.unconfirmed {
    svg {
      display: inline-block;
      vertical-align: top;
    }
  }
}
