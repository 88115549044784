@import "../../styles/colors.module.scss";

.tg-popper {
  background: $tg-white;
  border: 2px solid $tg-border-gray;
  border-radius: 8px;
  box-shadow: $tg-shadow;
  margin: 8px 0;
  visibility: hidden;
  z-index: map-get($z-index, "menu");

  &.tg-popper--small {
    padding: 8px 12px;
  }

  &.tg-popper--medium {
    padding: 16px 24px;
  }

  &.open {
    visibility: visible;
  }
}

.popper-anchor {
  color: $tg-product-blue;
  cursor: pointer;
}
