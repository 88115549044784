@import "../../styles/colors.module.scss";

.parsing-emails {
  position: relative;

  .parsing-emails-wrapper {
    border-radius: 8px;
    border: 2px solid $tg-border-gray;
    padding: 0 16px 16px;
    max-height: 296px;
    overflow-y: auto;
    margin-bottom: 32px;

    .parsing-emails__title {
      font-size: 16px;
      height: 52px;
      display: flex;
      align-items: center;
      position: sticky;
      top: 0;
      background: $tg-white;
      z-index: map-get($z-index, "app");

      .parsing-emails__results {
        font-size: 14px;
        margin-left: 8px;
      }
    }
  }
}
