@import "../../styles/colors.module";

.app-tooltip {
  font-size: 14px !important;
  background-color: $tg-black !important;
}

.app-tooltip-wrapper {
  display: flex;

  > div {
    display: flex;
  }
}
