@import "../../styles/colors.module.scss";

.app-status__dialog {
  .paper {
    position: absolute;
    margin: 0;
  }

  .paper-scroll-paper {
    border-radius: 0;
    min-width: 100%;
    min-height: 240px;
    max-height: calc(100% - 48px);
    padding: 16px 0;
  }

  .app-status__title {
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
      font-size: 24px;
      font-weight: normal;
      margin: 0;
    }
  }

  .app-status__content {
    overflow-y: visible;
    padding-bottom: 0;
  }

  .app-status__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .app-status__title {
      padding: 0;
    }
  }

  .app-status__sub-title {
    text-align: center;
    font-size: 20px;
    font-weight: normal;
    margin: 0 0 24px;
  }

  .app-status__description {
    text-align: center;
    font-size: 14px;
  }

  .app-status__actions {
    display: flex;
    justify-content: center;
    margin: 8px 24px 16px;
  }
}
