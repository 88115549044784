@import "../../styles/colors.module.scss";

.travel-filter {
  .travel-filter-type {
    padding: 16px 20px 24px;
    margin-bottom: 0;

    &:nth-child(1) {
      border-bottom: 1px solid $tg-border-gray;
    }

    .header {
      text-transform: uppercase;
      font-size: 10px;
      line-height: 13px;
      color: $tg-dark-gray;
      margin: 12px 0px;
    }
  }

  .travel-filter-row {
    display: flex;
    align-items: center;
    font-size: 14px !important;
    line-height: 18px !important;
    margin-bottom: 12px;

    .travel-select {
      min-width: 168px;
      margin-right: 8px;

      .travel-filter-select {
        height: 40px;
      }
    }

    .article-select {
      min-width: 80px;
      margin-right: 8px;
    }

    .filter-input {
      margin-right: 8px;
      max-width: 168px;
      min-width: 168px;

      .placeholder-input {
        height: 40px;
        background-color: $tg-background-gray;
        border: 2px solid $tg-border-gray;
        border-radius: 4px;
        box-sizing: border-box;
      }

      .tag-input {
        .tg-input__control {
          padding-left: 8px;
        }

        .tg-input__indicators {
          display: flex;
          align-items: center;
        }
      }

      .date-input-flex {
        height: 40x;
        .date-input {
          margin-bottom: 0px;
        }
        input {
          padding: 8px;
        }
      }

      .text-input {
        margin: 0;
        input {
          padding: 12px;
        }
      }

      .autocomplete-input--filled {
        margin: 0;
        min-height: 0;
      }

      .traveler-input {
        padding: 4px 8px;
      }
    }

    .andor-input {
      margin-right: 8px;
      .andor-select {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 40px;
        padding: 0 12px;
        border-radius: 4px;
        text-align: center;
        background-color: $tg-product-background-blue;
        color: $tg-product-blue;
        cursor: pointer;

        span {
          text-transform: capitalize;
        }
      }
    }

    .filter-tools {
      display: flex;
      align-items: center;
      justify-self: flex-end;

      svg {
        cursor: pointer;
      }
    }
  }

  .travel-filter-add-row {
    margin-top: 24px;
    span {
      color: $tg-product-blue;
      cursor: pointer;
    }
  }
}
