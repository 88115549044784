@import "../../../../styles/colors.module";

.popper-menu.column-menu {
  .tg-popper {
    height: 400px;
    padding: 16px 0px;

    .tg-popper-inner {
      box-sizing: border-box;
      height: 100%;
    }
  }

  .sink-column-menu {
    width: 382px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .title {
      font-size: 16px;
      color: $tg-text-gray;
      margin-bottom: 8px;
      padding: 0px 24px;
    }

    .content {
      height: 100%;
      overflow-y: auto;
      padding: 0px 24px;

      .sortables {
        .column-row {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 8px;
          padding: 4px 8px;
          border: 1px solid $tg-border-gray;
          border-radius: 4px;
          height: 48px;
          box-sizing: border-box;
          overflow: hidden;

          .label {
            font-size: 14px;
            flex-grow: 1;
          }

          span.MuiCheckbox-root {
            padding: 4px;
          }

          svg.icon.drag-handle {
            cursor: move;
          }

          &.disabled {
            .label {
              color: $tg-text-gray;
            }

            svg.icon {
              cursor: default;
              color: $tg-text-gray;
            }
          }

          .edit-button,
          .delete-button {
            cursor: pointer;
            height: 16px;
          }

          &.edit-row {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;

            .tg-form {
              display: flex;
              align-items: flex-start;
              justify-content: flex-start;
              flex-grow: 1;

              input.MuiInputBase-input {
                padding: 4px;
              }

              .input-controller {
                margin-right: 4px;
                flex-grow: 1;

                .input-controller-helper {
                  display: none;
                }
              }
            }

            button {
              margin: 2px 0px 0px 4px;
            }
          }
        }
      }

      .add-row {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        button {
          margin-left: 8px;
        }
      }
    }
  }
}
